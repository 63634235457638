// import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './App.css';
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable} from "react-native";
import { Fonts, Sizes, Colors } from '../Helper/Helper';
import { useNavigate } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
// import Mangas from './Mangas.json'
import { TouchableOpacity } from 'react-native-web';
import Kesfet from '../src/screens/Kesfet'




function Expolore(props) {

  const navigate = useNavigate();

  const Mangas = [
    {
        id:1,
        isim: "Elf Şovalyesi",
        image_url: require('../assets/kapak/Juji.png'),
        type: "webtoon",
        bölüm: 360,
        author: "Juji",
        artist: "Juji",
        pop: "1",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
    },
    {
        id:2,
        isim: "Küresel Cinsel Kültür Araştırma Enstitüsü",
        image_url: require('../assets/kapak/Küresel.png'),
        type: "webtoon",
        bölüm: 91,
        pop: "2",
        author: "Yorunezumi",
        artist: "Yorunezumi",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
    },
    {
        id:3,
        isim: "Isekai Fall of the Hero",
        image_url: require('../assets/kapak/isekai.webp'),
        type: "webtoon",
        pop:"3",
        bölüm: 112,
        author: "patimatik",
        artist: "patimatik",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Fantastik" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
    },
    {
      id:4,
      isim: "Elf Şovalyesi",
      image_url: require('../assets/kapak/Juji.png'),
      type: "webtoon",
      bölüm: 360,
      author: "Juji",
      artist: "Juji",
      pop: "1",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
  },
  {
      id:5,
      isim: "Küresel Cinsel Kültür Araştırma Enstitüsü",
      image_url: require('../assets/kapak/Küresel.png'),
      type: "webtoon",
      bölüm: 91,
      pop: "2",
      author: "Yorunezumi",
      artist: "Yorunezumi",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
  },
  {
      id:6,
      isim: "Isekai Fall of the Hero",
      image_url: require('../assets/kapak/isekai.webp'),
      type: "webtoon",
      pop:"3",
      bölüm: 112,
      author: "patimatik",
      artist: "patimatik",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Fantastik" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
  },
  {
    id:7,
    isim: "Elf Şovalyesi",
    image_url: require('../assets/kapak/Juji.png'),
    type: "webtoon",
    bölüm: 360,
    author: "Juji",
    artist: "Juji",
    pop: "1",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
},
{
    id:8,
    isim: "Küresel Cinsel Kültür Araştırma Enstitüsü",
    image_url: require('../assets/kapak/Küresel.png'),
    type: "webtoon",
    bölüm: 91,
    pop: "2",
    author: "Yorunezumi",
    artist: "Yorunezumi",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
},
{
    id:9,
    isim: "Isekai Fall of the Hero",
    image_url: require('../assets/kapak/isekai.webp'),
    type: "webtoon",
    pop:"3",
    bölüm: 112,
    author: "patimatik",
    artist: "patimatik",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Fantastik" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
}
  ];

  const [name, setName] = useState('Anill')
  const [currentPage, setCurrentPage] = useState('Kesfet');
  const [isHovered, setIsHovered] = useState(false);
  const [pressed, setPressed] = useState(false); // Başlangıçta false değeriyle başlatıyoruz
  const [mangaList, setMangaList] = useState([]);

  const [selectedManga, setSelectedManga] = useState(Mangas[0]);
  const [searchText, setSearchText] = useState('');
  const [filteredMangas, setFilteredMangas] = useState(Mangas);
  const [modal, setModal] = useState(false);



  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);


  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);




  




  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = Mangas.filter((manga) =>
      manga.isim.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredMangas(filtered);
  };

  // useEffect(() => {
  //   if (mangaList.length > 0 && !selectedManga) {
  //     setSelectedManga(mangaList[0]);
  //   }
  // }, []);

  const handleMangaClick = (id) => {
    const clickedManga = Mangas.find(manga => manga.id === id);
    setSelectedManga(clickedManga);
    console.log(selectedManga)
  };


  const datas = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1] 

  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`); 
  };


  
  const goBookDetail = () => {
    console.log('Naber'); 
    navigate(`/BookDetail`); 
  };


  const goPurchase = () => {
    console.log('Naber'); 
    navigate(`/Purchase`); 
  };

  
  const handlePresss = () => {
    setModal(!modal);
  };




  useEffect(() => {
    const filteredMangas = Mangas.filter(manga =>
      manga.isim.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredMangas(filteredMangas);
  }, [searchText]);


  function web(){
    return(
      <div className='App-header' >
      {/* <Link className='custom-link' to={'/Selam'} >
        <Text>Selamlar</Text>
      </Link> */}
       <View style={styles.navBar}>
        <Image style={{height:40,width:40, alignSelf:'center',marginTop:30}} source={require('../assets/Images/logo.png')}/>
        <View style={{alignItems:'center',marginTop:120}}>
          <Pressable onPress={goHomePage}>
            <img className='navIcons' style={styles.navIcons} src={currentPage == 'Anasayfa' ? require('../assets/Images/anasayfa.png'): require('../assets/Images/dogruBeyazAnasayfa.png')}/>
          </Pressable>
          <Pressable onPress={goExpolore} >
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/yesilKesfet.png')}/>
          </Pressable>
          <Pressable onPress={goBookCase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/satinalma.png')}/>
          </Pressable>
          <Pressable onPress={goLikedPAge}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/favori.png')}/>
          </Pressable>
          <Pressable onPress={goPurchase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/magaza.png')}/>
          </Pressable>
        </View>
      </View>


     
     <div className='middle-section' style={{marginTop:30,marginLeft:30}}>
        {/* <TextInput value={searchText} onChangeText={setSearchText} placeholderTextColor='white' style={styles.textInputs}  placeholder='Bir şey arayın...'/> */}
       
        <div style={{ display: 'flex', alignItems: 'center' , justifyContent:'space-between',backgroundColor:Colors.lightBlack,borderRadius:20}}>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Bir şey arayın..."
            style={{
              backgroundColor: 'rgba(26, 26, 26, 1)',
              width: 750,
              maxWidth:750,
              height: 80,
              borderRadius: 20,
              paddingLeft: 20,
              borderWidth: 0,
              color: '#fff',
              // Placeholder rengini beyaz yapmak için CSS kullanımı
              '::placeholder': {
                color: 'white'
              }
            }}
          />
          <img
            src={require('../assets/Images/search.png')}
            style={{marginRight:20 }} // Resmin boyutu ve diğer stiller
          />
        </div>


        {/* <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Bir şey arayın..."
          style={{
            backgroundColor: 'rgba(26, 26, 26, 1)',
            width: 906,
            height: 80,
            borderRadius: 20,
            paddingLeft: 20,
            borderWidth: 0,
            color: '#fff',
            // Placeholder rengini beyaz yapmak için CSS kullanımı
            '::placeholder': {
              color: 'white'
            }
          }}
        /> */}

        <div className='roboto' style={{fontSize:24, color:'#fff', fontWeight:'bold',marginTop:20}}>Keşfet</div>

       
        <FlatList
          numColumns={5}
          data={filteredMangas}
          style={{width:906}}
          renderItem={({item}) => 
            <View  style={styles.item} onClick={() => handleMangaClick(item.id)}>
              <Image source={{uri: item.image_url}} style={{ backgroundColor: '#D9D9D9', width: 162, height: 220 ,borderRadius:10}} />
              <div className='roboto' style={{ fontSize: 20, color: '#fff', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.isim}</div>
              <div className='roboto' style={{fontSize:16,color:'#fff'}}>Bölüm: {item.bölüm}</div>
              {/* <View style={{flexDirection:'row', backgroundColor:'#C7F14F', height:45, borderRadius:10, alignItems:'center',justifyContent:'center',marginTop:10}}>
                <div className='roboto' style={{color:'#111111', fontSize:16, fontWeight:'bold' }}>Devam Et</div>
                <Image style={{height:20,width:20,marginLeft:10}} source={require('../assets/Images/book.png')}/>
              </View> */}

            </View>
          }
        />
      </div>


      <View style={{marginRight:50}}>
        <View style={{ marginTop:30, marginLeft:30,flexDirection:'row'}}>
          <View style={{width:88, height:88, borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', alignItems:'center', justifyContent:'center'}}>
            <Image style={{height:28, width:28}} source={require('../assets/Images/noti.png')}/>
          </View>
          <View style={{width:257, height:88, marginLeft:10,borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
            <Image style={{backgroundColor:'#D9D9D9',height:40,width:40, borderRadius:100}}/>
            <View>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:16}}>Yuki Tanaka</div>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:14}}>info@gmail.com</div>
            </View>
            <Pressable onPress={handlePresss}>
              <Image source={require('../assets/Images/down.png')} style={{height:7,width:14,}}/>
            </Pressable>
          </View>
        </View>

                <View style={{backgroundColor:'blue', width:350,height:350,marginLeft:30,backgroundColor:Colors.lightBlack,marginTop:10,borderRadius:20,alignItems:'center',justifyContent:'center'}}>
          <div className='roboto' style={{color:'#FFFFFF', fontSize:32,marginBottom:15}}>{selectedManga.isim}</div>
          <Image source={{uri:selectedManga.image_url}} style={{ backgroundColor: '#D9D9D9', width: 162, height: 220 ,borderRadius:10}} />


        </View>

        {modal ? (<View style={{backgroundColor:Colors.lightBlack,height:279, position:'absolute',width:250,right:4,top:105,padding:15, borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
            <View style={{borderWidth:0.5,borderColor:'#fff',marginTop:10,marginBottom:10}}></View>
            {/* <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/aile.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Aile Modu</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/karanlik.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Karanlık Mod</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/tema.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Tema</Text>
            </View>
            <Image source={require('../assets/Images/renkler.png')} style={{height:24,width:212}}/> */}

            <Pressable onPress={() => navigate('/Profile')} style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/profilim.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Profilim</Text>
            </Pressable>
            {/* <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/ayarlar.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Ayarlar</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/cikisYap.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Çıkış Yap</Text>
            </View> */}
        </View>): null}



        <View style={styles.bottoms}>
          <View style={{margin:20}}>
            <div className='roboto' style={{fontSize:Sizes.bigTitle,color:'#fff'}}>Özet</div>
            <div className='roboto' style={{fontSize:Sizes.smallTitle,color:Colors.darkWhite,marginTop:10}}>{selectedManga.summary}</div>
            <View style={{flexDirection:'row',marginTop:10}}>
              <View style={styles.desc}>
                <div className='roboto tur'>Tür: <span className='name'>{selectedManga.type}</span></div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Yazar: <span className='name'>{selectedManga.author}</span></div>
              </View>
            </View>
            <View style={{flexDirection:'row', marginTop:10}}>
              <View style={styles.desc}>
                <div className='roboto tur'>{selectedManga.type}</div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Aksiyon</div>
              </View>
            </View>
            <View style={{flexDirection:'row', marginTop:10,}}>
              <View style={styles.desc}>
                <div className='roboto tur'>Doğa Üstü Güçler</div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Dövüş Sanatları</div>
              </View>
            </View>
            <View style={{marginTop:20, flexDirection:'row',justifyContent:'space-between'}}>
              <Pressable onPress={() => console.log('Selamm')} style={{height:88,width:234, backgroundColor:'#C7F14F',flexDirection:'row', justifyContent:'center', alignItems:'center', borderRadius:20}}>
                <div className='roboto' style={{fontSize:16,color:'#111111', fontWeight:'bold'}}>Şimdi Oku</div>
                <Image style={{height:24, width:24,marginLeft:15}} source={require('../assets/Images/book.png')}/>
              </Pressable>
              <Pressable
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 88,
                  width: 58,
                  backgroundColor: Colors.lightBlack,
                  borderWidth: 1,
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  borderRadius: 20
                }}
                onPress={() =>setPressed(!pressed)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {/* <img
                  className='kalp'
                  style={{ height: 24, width: 24 }}
                  src={isHovered ? require('../assets/Images/liked.png') : require('../assets/Images/kalp.png')}
                /> */}
                {pressed ? (<img
                  className='kalp'
                  
                  src={require('../assets/Images/filledLiked.png') }
                />): (<img
                  className='kalp'
                  style={{ height: 24, width: 24 }}
                  src={isHovered ? require('../assets/Images/liked.png') : require('../assets/Images/kalp.png')}
                />)}
                
              </Pressable>
            </View>
          </View>
        </View>
      </View>

      

      
    </div>
    )
  }

  
  return (
    <body>
      {name > 906 && web()}
      {name < 906 && <Kesfet/>}
    </body>
   
  );
}

const styles = StyleSheet.create({
  container:{
  
    flexDirection:'row',
    backgroundColor:'rgba(17, 17, 17, 1)',
    height:Dimensions.get('window').height,
    width:Dimensions.get('window').width,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw'
      }
    })
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    scrollbarWidth: 'none', // Firefox için
    WebkitOverflowScrolling: 'touch', // Safari için
    '-ms-overflow-style': 'none' // IE11 için
  },

  item: {
    marginTop:10,
    marginRight:20
  },

  navBar:{
    backgroundColor:Colors.lightBlack,
    width:100,
    height:Dimensions.get('window').height/1.2,
    marginTop:20,
    borderRadius:20,
    marginLeft:150,

  },
  navIcons:{
    height:40,
    width:40,
    marginTop:25,
  },
  textInputs:{
    backgroundColor: 'rgba(26, 26, 26, 1)',
    width: 906,
    height: 80,
    borderRadius: 20,
    paddingLeft: 20,
    borderWidth:0,
    color:'#fff'
  },
  bottoms:{
    backgroundColor:'rgba(26, 26, 26, 1)',
    marginTop:15,
    height:490,
    width:350,
    marginLeft:30,
    borderRadius:20
  },
  desc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    padding:5
  },
  rightDesc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    marginLeft:10 ,
    padding:5
  },
})

export default Expolore;
