import React,{useState, useEffect} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList, StatusBar} from 'react-native'
import { useNavigate ,useLocation} from 'react-router-dom';


const Detay = (props) => {


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  const [manga, setManga] = useState(id);

 

  useEffect(() => {
    if (id) {
      // Manga verilerini id'ye göre bul
      const foundManga = mangaData.find(item => item.id === parseInt(id, 10));
      setManga(foundManga); // Bulunan veriyi state'e set et
    }
  }, [id]);
  



  const goHomePage = () => {
    navigate(`/`,{ replace: true });
    console.log(openModel)
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`,{ replace: true }); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`,{ replace: true }); 
  };

  const goBookCase = () => {
    navigate(`/BookCase`,{ replace: true }); 
  };


  const goBookDetail = () => {
    navigate(`/BookDetail`,{ replace: true }); 
  };

  const goPurchase = () => {
    navigate(`/Purchase`,{ replace: true }); 
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true}); 
  };

  const goRead = () => {
    navigate(`/Reading`,{replace:true}); 
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };



  const fakeData = [
    {
      "chapterNumber": 101,
      "chapterName": "The Beginning",
      "weeksAgoPublished": 12,
      "purchased": true
    },
    {
      "chapterNumber": 102,
      "chapterName": "Rising Shadows",
      "weeksAgoPublished": 10,
      "purchased": false
    },
    {
      "chapterNumber": 103,
      "chapterName": "The Hidden Truth",
      "weeksAgoPublished": 8,
      "purchased": true
    },
    {
      "chapterNumber": 104,
      "chapterName": "Echoes of the Past",
      "weeksAgoPublished": 5,
      "purchased": false
    },
    {
      "chapterNumber": 105,
      "chapterName": "A New Threat",
      "weeksAgoPublished": 3,
      "purchased": true
    },
    {
      "chapterNumber": 106,
      "chapterName": "Unveiling Secrets",
      "weeksAgoPublished": 1,
      "purchased": false
    }
  ];


  const mangaData = [
    {
        id:1,
        name: "Elf Şovalyesi",
        image_url: require('../../assets/kapak/Juji.png'),
        type: "webtoon",
        bölüm: 360,
        author: "Juji",
        artist: "Juji",
        weeksAgoPublished:1,
        purchased:true,
        pop: "1",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
    },
    {
        id:2,
        name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
        image_url: require('../../assets/kapak/Küresel.png'),
        type: "webtoon",
        bölüm: 91,
        pop: "2",
        author: "Yorunezumi",
        artist: "Yorunezumi",
        weeksAgoPublished:4,
        purchased:false,
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
    },
    {
        id:3,
        name: "Isekai Fall of the Hero",
        image_url: require('../../assets/kapak/isekai.webp'),
        type: "webtoon",
        pop:"3",
        bölüm: 112,
        author: "patimatik",
        artist: "patimatik",
        weeksAgoPublished:2,
        purchased:false,
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Fantastik" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
    },
    {
      id:4,
      name: "Elf Şovalyesi",
      image_url: require('../../assets/kapak/Juji.png'),
      type: "webtoon",
      bölüm: 360,
      author: "Juji",
      artist: "Juji",
      weeksAgoPublished:1,
      purchased:true,
      pop: "1",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
  },
  {
      id:5,
      name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
      image_url: require('../../assets/kapak/Küresel.png'),
      type: "webtoon",
      bölüm: 91,
      weeksAgoPublished:2,
      purchased:false,
      pop: "2",
      author: "Yorunezumi",
      artist: "Yorunezumi",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
  },
  {
      id:6,
      name: "Isekai Fall of the Hero",
      image_url: require('../../assets/kapak/isekai.webp'),
      type: "webtoon",
      pop:"3",
      bölüm: 112,
      weeksAgoPublished:2,
      purchased:true,
      author: "patimatik",
      artist: "patimatik",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Fantastik" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
  },
  {
    id:7,
    name: "Elf Şovalyesi",
    image_url: require('../../assets/kapak/Juji.png'),
    type: "webtoon",
    weeksAgoPublished:3,
    purchased:true,
    bölüm: 360,
    author: "Juji",
    artist: "Juji",
    pop: "1",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
},
{
    id:8,
    name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
    image_url: require('../../assets/kapak/Küresel.png'),
    type: "webtoon",
    bölüm: 91,
    pop: "2",
    author: "Yorunezumi",
    artist: "Yorunezumi",
    weeksAgoPublished:1,
    purchased:true,
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
},
{
    id:9,
    weeksAgoPublished:1,
    purchased:false,
    name: "Isekai Fall of the Hero",
    image_url: require('../../assets/kapak/isekai.webp'),
    type: "webtoon",
    pop:"3",
    bölüm: 112,
    author: "patimatik",
    artist: "patimatik",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Fantastik" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
}
  ];


  const comments =  [
    {
      "username": "movieBuff92",
      "time": 15,
      "description": "A gripping tale of adventure and mystery as a young hero embarks on a journey to uncover ancient secrets hidden deep within a forgotten world. With breathtaking visuals and a captivating storyline, this film will keep you on the edge of your seat."
    },
    {
      "username": "cinemaLover88",
      "time": 8,
      "description": "A heartwarming story of love and friendship."
    },
    {
      "username": "filmFanatic77",
      "time": 22,
      "description": "An epic saga of war and honor set in a world of magic and fantasy. Follow the journey of a warrior destined to change the fate of his people."
    },
    {
      "username": "screenCritic21",
      "time": 10,
      "description": "A thrilling chase through the streets of a dystopian future, where every moment counts and survival is not guaranteed."
    },
    {
      "username": "blockbusterFan23",
      "time": 19,
      "description": "A light-hearted romantic comedy that will make you laugh and cry."
    },
    {
      "username": "actionHero45",
      "time": 13,
      "description": "Explosions, high-speed chases, and non-stop action in this adrenaline-pumping blockbuster."
    }
  ]
  

  const [openModel, setOpenModal] = useState(false)


  function draweModalRender(){
    return(
      <SafeAreaView style={{flex:1,position:'absolute',backgroundColor:'#1A1A1A',width:'100%',height:'100%'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
          <TouchableOpacity style={{flexDirection:'row'}} onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/cik.png')}/>
            <Image style={{marginLeft:10,height:40,width:40}} source={require('../assets/popuplogo.png')}/>

          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={goNotification}>
              <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
            </TouchableOpacity>
            <Image style={{marginLeft:10,height:40,width:80}} source={require('../assets/giris.png')}/>
          </View>
        </View>
        <View style={{backgroundColor:'#1A1A1A', flex:1, alignItems:'center', marginTop:15}}>
          <View>
            <TouchableOpacity  onPress={goHomePage} style={{flexDirection:'row', alignItems:'center'}}>
              <Image style={{height:40,width:40}} source={require('../assets/anasayfa.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Anasayfa</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goExpolore} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kesfet.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Keşfet</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBookCase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kitaplik.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Kitaplık</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goLikedPAge} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/favoriler.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Favoriler</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goPurchase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/coin.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Coin Satın Al</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }


  return (
    <SafeAreaView style={{flex:1,backgroundColor:'black'}}>
      <StatusBar backgroundColor={'black'} barStyle={'light-content'}/>
      <ScrollView>
      <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image style={{height:40,width:90}} source={require('../assets/menu.png')}/>
        </TouchableOpacity>
        <View style={{flexDirection:'row'}}>
          <TouchableOpacity onPress={goNotification}>
            <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={goProfile}>
            <Image style={{marginLeft:10, height:40,width:128}} source={require('../assets/kullanici.png')}/>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{height:350,margin:10,backgroundColor:'#1A1A1A',borderRadius:20,alignItems:'center', justifyContent:'center'}}>
        <Image source={manga.image_url} style={{backgroundColor:'#fff', height:222,width:'45%',borderRadius:15}}/>
        <Text style={{fontSize:32,fontWeight:'600', color:'#fff',marginTop:10}}>{manga.name}</Text>
      </View>


      <View style={{margin:10,backgroundColor:'#1A1A1A',borderRadius:20}}>
        <View style={{margin:10}}>
          <Text style={{fontSize:22,fontWeight:'600', color:'#fff',marginTop:10}}>Özet:</Text>
          <Text style={{fontSize:16,fontWeight:'400',marginTop:7,color:'#cccccc'}}>{manga.summary}</Text>
          <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start'}}>
            <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Tür: {manga.type}</Text>
          </View>

          <View style={{flexDirection:'row'}}>
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start'}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Yazar: {manga.author}</Text>
            </View>
            
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start',marginLeft:10}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Sanatçı: {manga.artist}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row'}}>
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start'}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Bilimkurgu</Text>
            </View>
            
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start',marginLeft:10}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Aksiyon</Text>
            </View>
          </View>
          <View style={{flexDirection:'row'}}>
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start'}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Doğa üstü güçler</Text>
            </View>
            
            <View style={{backgroundColor:'#2A2A2A', padding:10,borderRadius:20,marginTop:10,alignSelf: 'flex-start',marginLeft:10}}>
              <Text style={{fontSize:14, fontWeight:'400',color:'#fff',}}>Dövüş Sanatları</Text>
            </View>
          </View>

          <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
              <TouchableOpacity onPress={goRead} style={{width:234,height:56,backgroundColor:'#C7F14F',flexDirection:'row', alignItems:'center',justifyContent:'center', borderRadius:20}}>
                <Text style={{color:'black', fontSize:16,fontWeight:'600'}}>Şimdi Oku</Text>
                <Image style={{marginLeft:10, height:20,width:20}} source={require('../assets/book.png')}/>
              </TouchableOpacity>
              <Image style={{height:56,width:60,marginRight:20}} source={require('../assets/buyukkalp.png')}/>
          </View>

        </View>

      </View>
      
      <View style={{margin:10,flexDirection:'row'}}>
        <Image style={{height:20,width:20}} source={require('../assets/downarrow.png')}/>
        <Text style={{fontSize:16,fontWeight:'400',color:'#fff',marginLeft:10}}>Recently Added</Text>
      </View>

      <>
        {mangaData.map((item) =>
          <View style={{marginLeft:10,marginTop:10,flexDirection:'row'}}>
            <Image source={item.image_url} style={{backgroundColor:'#fff',height:138,width:107,borderRadius:10}}/>
            <View style={{marginLeft:10}}>
              <Text style={{fontSize:16,fontWeight:'600', color:'#ffff'}}>Bölüm: {item.bölüm}</Text>
              <Image style={{height:20,width:20}} source={require('../assets/duble.png')}/>
              <Text numberOfLines={1}  style={{fontSize:16,fontWeight:'600', color:'#ffff',maxWidth:'65%'}}>{item.name}</Text>
              <View>
                <Image style={{height:20,width:20}} source={require('../assets/takvim.png')}/>
                <Text style={{fontSize:13,fontWeight:'400', color:'#cccccc'}}>{item.weeksAgoPublished} hafta önce yayınlandı</Text>
              </View>
            </View>
            {item.purchased ? (<View style={{alignItems:'center',justifyContent:'center'}}>
              
              <Image style={{height:40,width:97}} source={require('../assets/alindi.png')}/>
            </View>):null}
          </View>
        )}
      </>


      <View style={{backgroundColor:'#1A1A1A',margin:10,borderRadius:20}}>
        <View style={{margin:10}}>
          <Text style={{fontSize:22,fontWeight:'600',color:'#fff',marginBottom:15}}>Comments</Text>
          
          {comments.map((item) =>
            <View style={{}}>
            <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
              <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <Image style={{height:35,width:35,backgroundColor:'#fff',borderRadius:100}}/>
                <Text style={{fontSize:16,fontWeight:'500', color:'#fff',marginLeft:10}}>{item.username}</Text>
              </View>
              <View style={{flexDirection:'row'}}>
                <Image style={{height:20,width:20}} source={require('../assets/saat.png')}/>
                <Text style={{fontSize:16,fontWeight:'500', color:'#cccccc',marginLeft:5}}>{item.time} Saat</Text>
                <Image style={{marginLeft:20,height:20,width:20}} source={require('../assets/dot.png')}/>
              </View>
            </View>
            <Text style={{marginTop:10,fontSize:14,fontWeight:'400', color:'#cccc'}}>{item.description}</Text>
          </View>
          )}

        </View>

      </View>
      
     

      </ScrollView>

      {openModel == true && draweModalRender()}
      
    </SafeAreaView>
  )
}

export default Detay