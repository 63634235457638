// import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './App.css';
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable} from "react-native";
import { Fonts, Sizes, Colors } from '../Helper/Helper';
import { useNavigate } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
import Mangas from './Mangas.json'
import Oku from '../src/screens/Oku'



function Reading(props) {

  const navigate = useNavigate();

  const [name, setName] = useState('Anill')
  const [currentPage, setCurrentPage] = useState('Kesfet');
  const [isHovered, setIsHovered] = useState(false);
  const [pressed, setPressed] = useState(false); // Başlangıçta false değeriyle başlatıyoruz
  const [mangaList, setMangaList] = useState([]);

  const [selectedManga, setSelectedManga] = useState(Mangas[0]);
  const [searchText, setSearchText] = useState('');
  const [filteredMangas, setFilteredMangas] = useState(Mangas);

  const [openComment, setOpenComment] = useState(false);


  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);


  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);






  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = Mangas.filter((manga) =>
      manga.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredMangas(filtered);
  };

  // useEffect(() => {
  //   if (mangaList.length > 0 && !selectedManga) {
  //     setSelectedManga(mangaList[0]);
  //   }
  // }, []);

  const handleMangaClick = (id) => {
    const clickedManga = Mangas.find(manga => manga.id === id);
    setSelectedManga(clickedManga);
    console.log(selectedManga)
  };


  const datas = [1,1,1,1,1,1,1,1,1,1,1,1,1,1] 

  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`); 
  };


  
  const goBookDetail = () => {
    console.log('Naber'); 
    navigate(`/BookDetail`); 
  };


  const goPurchase = () => {
    console.log('Naber'); 
    navigate(`/Purchase`); 
  };



  useEffect(() => {
    const filteredMangas = Mangas.filter(manga =>
      manga.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredMangas(filteredMangas);
  }, [searchText]);

  
  const [modal, setModal] = useState(false);

  const handlePresss = () => {
    setModal(!modal);
  };


  function web(){
    return(
      <div className='App-header' >
      {/* <Link className='custom-link' to={'/Selam'} >
        <Text>Selamlar</Text>
      </Link> */}
       <View style={styles.navBar}>
        <Image style={{height:40,width:40, alignSelf:'center',marginTop:30}} source={require('../assets/Images/logo.png')}/>
        <View style={{alignItems:'center',marginTop:120}}>
          <Pressable onPress={goHomePage}>
            <img className='navIcons' style={styles.navIcons} src={currentPage == 'Anasayfa' ? require('../assets/Images/anasayfa.png'): require('../assets/Images/dogruBeyazAnasayfa.png')}/>
          </Pressable>
          <Pressable onPress={goExpolore} >
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/yesilKesfet.png')}/>
          </Pressable>
          <Pressable onPress={goBookCase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/satinalma.png')}/>
          </Pressable>
          <Pressable onPress={goLikedPAge}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/favori.png')}/>
          </Pressable>
          <Pressable onPress={goPurchase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/magaza.png')}/>
          </Pressable>
        </View>
      </View>


     
     <div className='middle-section' style={{marginTop:30,marginLeft:30}}>
        {/* <TextInput value={searchText} onChangeText={setSearchText} placeholderTextColor='white' style={styles.textInputs}  placeholder='Bir şey arayın...'/> */}
       <View style={{borderWidth:0.2,borderColor:Colors.lightBlack, height:75,alignItems:'center', justifyContent:'center',borderRadius:20}}>
        <div className='roboto' style={{textAlign:'center',fontSize:18}}>105. Bölüm: Festival Hazırlıkları</div>
       </View>


        {/* <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Bir şey arayın..."
          style={{
            backgroundColor: 'rgba(26, 26, 26, 1)',
            width: 906,
            height: 80,
            borderRadius: 20,
            paddingLeft: 20,
            borderWidth: 0,
            color: '#fff',
            // Placeholder rengini beyaz yapmak için CSS kullanımı
            '::placeholder': {
              color: 'white'
            }
          }}
        /> */}

        {/* <div className='roboto' style={{fontSize:24, color:'#fff', fontWeight:'bold',marginTop:20}}>Keşfet</div> */}

       
        <FlatList
          data={datas}
          style={{width:906}}
          renderItem={({item}) => 
            <View  style={{height:846, width:615,borderRadius:25, backgroundColor:Colors.lightBlack, alignSelf:'center', alignItems:'center', justifyContent:'center',marginBottom:25,marginTop:15}} onClick={() => handleMangaClick(item.id)}>
              <View style={{width:519,height:750, backgroundColor:'#fff'}}>
                

              </View>
            </View>
          }
        />
      </div>


      <View style={{marginRight:50}}>
        <View style={{ marginTop:30, marginLeft:30,flexDirection:'row'}}>
          <Pressable onPress={() => setOpenComment(true)} style={{width:88, height:88, borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', alignItems:'center', justifyContent:'center'}}>
            <Image style={{height:28, width:28}} source={require('../assets/Images/comment.png')}/>
          </Pressable>
          <View style={{width:257, height:88, marginLeft:10,borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
            <Image style={{backgroundColor:'#D9D9D9',height:40,width:40, borderRadius:100}}/>
            <View>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:16}}>Yuki Tanaka</div>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:14}}>info@gmail.com</div>
            </View>
            <Pressable onPress={handlePresss}>
              <Image source={require('../assets/Images/down.png')} style={{height:7,width:14,}}/>
            </Pressable>
          </View>
        </View>

        <View style={{backgroundColor:'blue', width:350,height:350,marginLeft:30,backgroundColor:Colors.lightBlack,marginTop:10,borderRadius:20,alignItems:'center',justifyContent:'center'}}>
          <div className='roboto' style={{color:'#FFFFFF', fontSize:32,marginBottom:15}}>{selectedManga.title}</div>
          <Image source={{uri:selectedManga.cover_image_url}} style={{ backgroundColor: '#D9D9D9', width: 162, height: 220 ,borderRadius:10}} />


        </View>

        {modal ? (<View  style={{backgroundColor:Colors.lightBlack,height:279, position:'absolute',width:250,right:4,top:105,padding:15, borderBottomLeftRadius:20,borderBottomRightRadius:20,zIndex:99}}>
            <View style={{borderWidth:0.5,borderColor:'#fff',marginTop:10,marginBottom:10}}></View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/aile.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Aile Modu</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/karanlik.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Karanlık Mod</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/tema.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Tema</Text>
            </View>
            <Image source={require('../assets/Images/renkler.png')} style={{height:24,width:212}}/>

            <Pressable onPress={() => navigate('/Profile')} style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/profilim.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Profilim</Text>
            </Pressable>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/ayarlar.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Ayarlar</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/cikisYap.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Çıkış Yap</Text>
            </View>
        </View>): null}
         


        <View style={styles.bottoms}>
          <View style={{margin:20}}>
            <div className='roboto' style={{fontSize:Sizes.bigTitle,color:'#fff'}}>Özet</div>
            <div className='roboto' style={{fontSize:Sizes.smallTitle,color:Colors.darkWhite,marginTop:10}}>{selectedManga.summary}</div>
            <View style={{flexDirection:'row',marginTop:10}}>
              <View style={styles.desc}>
                <div className='roboto tur'>Tür: <span className='name'>Name</span></div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Yazar: <span className='name'>{selectedManga.author}</span></div>
              </View>
            </View>
            <View style={{flexDirection:'row', marginTop:10}}>
              <View style={styles.desc}>
                <div className='roboto tur'>{selectedManga.genre}</div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Aksiyon</div>
              </View>
            </View>
            <View style={{flexDirection:'row', marginTop:10,}}>
              <View style={styles.desc}>
                <div className='roboto tur'>Doğa Üstü Güçler</div>
              </View>
              <View style={styles.rightDesc}>
                <div className='roboto tur'>Dövüş Sanatları</div>
              </View>
            </View>
            <View style={{marginTop:20, flexDirection:'row',justifyContent:'space-between'}}>
              <Pressable  style={{height:88,width:146, backgroundColor:'#C7F14F',flexDirection:'row', justifyContent:'center', alignItems:'center', borderRadius:20}}>
                <Image style={{height:24, width:24,marginRight:10}} source={require('../assets/Images/leftArrow.png')}/>
                <div className='roboto' style={{fontSize:16,color:'#111111', fontWeight:'bold'}}>Önceki</div>
              </Pressable>

              <Pressable  style={{height:88,width:146, backgroundColor:'#C7F14F',flexDirection:'row', justifyContent:'center', alignItems:'center', borderRadius:20}}>
                <div className='roboto' style={{fontSize:16,color:'#111111', fontWeight:'bold'}}>Sonraki</div>
                <Image style={{height:24, width:24,marginLeft:10}} source={require('../assets/Images/rightArrow.png')}/>
              </Pressable>
              

            </View>
          </View>
        </View>
      </View>

  {openComment ? (<Pressable onPress={() => setOpenComment(false)} style={{height:'100%',  backgroundColor: 'rgba(0, 0, 0, 0.8)', position:'absolute', width:'100%', justifyContent:'flex-end', alignItems:'center'}}>

<View style={{backgroundColor:Colors.lightBlack ,width:'65%',height:'75%',bottom:0,padding:50,borderRadius:25}}>
  <div indicatorStyle='green' className='comment' style={{}}>
    {datas.map((item) => (
      <View style={{marginTop:15,marginBottom:15}}  >
        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
          <View style={{flexDirection:'row', alignItems:'center'}}>
            <Image  style={{width: 32, height: 32 ,marginRight:5,borderRadius:50,backgroundColor:'#fff'}} />
            <div className='roboto' style={{fontSize:Sizes.smallTitle, color:'#fff'}}>User89712647</div>
          </View>
          <View style={{flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
            <Image  source={require('../assets/Images/saat.png')} style={{width: 24, height: 24,marginRight:15}} />
            <div className='name' style={{fontSize:Sizes.smallTitle,marginRight:15}}>1 saat</div>
            <Image source={require('../assets/Images/dot.png')}  style={{width: 24, height: 24 ,marginRight:15}} />
          </View>
        </View>
        <div className='roboto' style={{fontSize:Sizes.smallTitle,marginTop:5}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce rhoncus nibh erat, sit amet fringilla sem viverra at. Aliquam erat volutpat. Quisque iaculis sem et feugiat molestie.</div>
      </View>
    ))}
  </div>


  <div style={{ display: 'flex', alignItems: 'center' , justifyContent:'space-between',backgroundColor: 'rgba(77,77,77,255)',borderRadius:20,marginTop:15}}>
  <input
    type="text"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    placeholder="Bir yorum bırak"
    style={{
      backgroundColor: 'rgba(77,77,77,255)',
      width: 750,
      maxWidth:750,
      height: 64,
      borderRadius: 20,
      paddingLeft: 20,
      borderWidth: 0,
      color: '#fff',
      // Placeholder rengini beyaz yapmak için CSS kullanımı
      '::placeholder': {
        color: 'white'
      }
    }}
  />
  <Pressable onPress={() => setOpenComment(false)}>
    <img
      src={require('../assets/Images/sendComment.png')}
      style={{marginRight:20 }} // Resmin boyutu ve diğer stiller
    />
  </Pressable>
</div>
</View>
</Pressable>): null}

      
    </div>
    )
  }



  
  return (
    <body>
      {name > 906 && web()}
      {name < 906 && <Oku/>}

    </body>
   
  );
}

const styles = StyleSheet.create({
  container:{
  
    flexDirection:'row',
    backgroundColor:'rgba(17, 17, 17, 1)',
    height:Dimensions.get('window').height,
    width:Dimensions.get('window').width,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw'
      }
    })
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    scrollbarWidth: 'none', // Firefox için
    WebkitOverflowScrolling: 'touch', // Safari için
    '-ms-overflow-style': 'none' // IE11 için
  },

  item: {
    marginTop:10,
    marginRight:20
  },

  navBar:{
    backgroundColor:Colors.lightBlack,
    width:100,
    height:Dimensions.get('window').height/1.2,
    marginTop:20,
    borderRadius:20,
    marginLeft:150,

  },
  navIcons:{
    height:40,
    width:40,
    marginTop:25,
  },
  textInputs:{
    backgroundColor: 'rgba(26, 26, 26, 1)',
    width: 906,
    height: 80,
    borderRadius: 20,
    paddingLeft: 20,
    borderWidth:0,
    color:'#fff'
  },
  bottoms:{
    backgroundColor:'rgba(26, 26, 26, 1)',
    marginTop:15,
    height:490,
    width:350,
    marginLeft:30,
    borderRadius:20
  },
  desc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    padding:5
  },
  rightDesc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    marginLeft:10 ,
    padding:5
  },
})

export default Reading;
