// import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './App.css';
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable, TouchableOpacity} from "react-native";
import { Fonts, Sizes, Colors } from '../Helper/Helper';
import { useNavigate ,useLocation } from 'react-router-dom';
import React,{useState, useEffect, useRef} from 'react';
import Mangas from './Mangas.json'
import SecondMangas from './SecondMangas.json'
import Seriler from './seriler.json'
import SerilerCok from './SerilerCok.json'
import Anasayfa from '../src/screens/Anasayfa'

import 'react-slideshow-image/dist/styles.css'
import { Fade, Zoom, Slide } from 'react-slideshow-image';










function HomePage(props) {



  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);
  const [name, setName] = useState('Anill')


  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);




  

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState('Anasayfa');
  const [modal, setModal] = useState(false);






  const datas = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1] 


  // const handlePress = () => {
  //   console.log('Naber'); // Konsola mesaj yazdırma
  //   navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  // };

  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`); 
  };


  const goBookDetail = (item) => {
    navigate(`/BookDetail`,{ state: { id: item } }); 
  };

  const goPurchase = () => {
    console.log('Naber'); 
    navigate(`/Purchase`,{replace:true}); 
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true}); 
  };







  const handlePresss = () => {
    setModal(!modal);
  };


  const mangaData = [
    {
        id:1,
        name: "Elf Şovalyesi",
        image_url: require('../assets/kapak/Juji.png'),
        type: "webtoon",
        bölüm: 360,
        author: "Juji",
        artist: "Juji",
        pop: "1",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
    },
    {
        id:2,
        name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
        image_url: require('../assets/kapak/Küresel.png'),
        type: "webtoon",
        bölüm: 91,
        pop: "2",
        author: "Yorunezumi",
        artist: "Yorunezumi",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
    },
    {
        id:3,
        name: "Isekai Fall of the Hero",
        image_url: require('../assets/kapak/isekai.webp'),
        type: "webtoon",
        pop:"3",
        bölüm: 112,
        author: "patimatik",
        artist: "patimatik",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Fantastik" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
    },
    {
      id:4,
      name: "Elf Şovalyesi",
      image_url: require('../assets/kapak/Juji.png'),
      type: "webtoon",
      bölüm: 360,
      author: "Juji",
      artist: "Juji",
      pop: "1",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
  },
  {
      id:5,
      name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
      image_url: require('../assets/kapak/Küresel.png'),
      type: "webtoon",
      bölüm: 91,
      pop: "2",
      author: "Yorunezumi",
      artist: "Yorunezumi",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
  },
  {
      id:6,
      name: "Isekai Fall of the Hero",
      image_url: require('../assets/kapak/isekai.webp'),
      type: "webtoon",
      pop:"3",
      bölüm: 112,
      author: "patimatik",
      artist: "patimatik",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Fantastik" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
  },
  {
    id:7,
    name: "Elf Şovalyesi",
    image_url: require('../assets/kapak/Juji.png'),
    type: "webtoon",
    bölüm: 360,
    author: "Juji",
    artist: "Juji",
    pop: "1",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
},
{
    id:8,
    name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
    image_url: require('../assets/kapak/Küresel.png'),
    type: "webtoon",
    bölüm: 91,
    pop: "2",
    author: "Yorunezumi",
    artist: "Yorunezumi",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
},
{
    id:9,
    name: "Isekai Fall of the Hero",
    image_url: require('../assets/kapak/isekai.webp'),
    type: "webtoon",
    pop:"3",
    bölüm: 112,
    author: "patimatik",
    artist: "patimatik",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Fantastik" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
}
  ];
  

  function web(){
    return(
      <div className='App-header' >
      <View style={styles.navBar}>
        <Image style={{height:40,width:40, alignSelf:'center',marginTop:30}} source={require('../assets/Images/logo.png')}/>
        <View style={{alignItems:'center',marginTop:120}}>
          <Pressable onPress={goHomePage}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/anasayfa.png')}/>
          </Pressable>
          <Pressable onPress={goExpolore} >
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/kesfet.png')}/>
          </Pressable>
          <Pressable onPress={goBookCase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/satinalma.png')}/>
          </Pressable>
          <Pressable onPress={goLikedPAge}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/favori.png')}/>
          </Pressable>
          <Pressable onPress={goPurchase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/magaza.png')}/>
          </Pressable>
        </View>
      </View>


     
     <div className='middle-section' style={{marginTop:30,marginLeft:30}}>
     <div style={{ display: 'flex', alignItems: 'center' , justifyContent:'space-between',backgroundColor:Colors.lightBlack,borderRadius:20}}>
          <input
            type="text"
            placeholder="Bir şey arayın..."
            style={{
              backgroundColor: 'rgba(26, 26, 26, 1)',
              width: 750,
              maxWidth:750,
              height: 80,
              borderRadius: 20,
              paddingLeft: 20,
              borderWidth: 0,
              color: '#fff',
              // Placeholder rengini beyaz yapmak için CSS kullanımı
              '::placeholder': {
                color: 'white'
              }
            }}
          />
          <img
            src={require('../assets/Images/search.png')}
            style={{marginRight:20 }} // Resmin boyutu ve diğer stiller
          />
        </div>



   

       <div className='slide-container' style={{height:437,width:906,marginTop:15,}}>
            <Fade >
              {mangaData.map((item, index) => (
                  <ImageBackground style={{height:437,width:906,marginBottom:20,backgroundColor:Colors.lightBlack, borderRadius:20}}   source={{uri:item.image_url}}>
                      <View style={{flexDirection:'row', justifyContent:'space-between',margin:15}}>
                        <View style={{flexDirection:'row'}}>
                          <View style={{borderWidth:1,borderColor:'rgba(255, 255, 255, 0.3)',flexDirection:'row', backgroundColor:'rgba(255, 255, 255, 0.5)', height:51, width:134, alignItems:'center', justifyContent:'center', borderRadius:20}}>
                            <Image style={{height:16, width:16}} source={require('../assets/Images/fires.png')}/>
                            <div className='roboto' style={{color:'#fff', fontSize:16}}>Popüler #{item.pop}</div>
                          </View>
                          <View style={{marginLeft:15,borderWidth:1,borderColor:'rgba(255, 255, 255, 0.3)',backgroundColor:'rgba(255, 255, 255, 0.5)', height:51, width:84, alignItems:'center', justifyContent:'center', borderRadius:20}}>
                            <div className='roboto' style={{color:'#fff', fontSize:16}}>{item.type}</div>
                          </View>
                        </View>
                        <View style={{borderWidth:1,borderColor:'rgba(255, 255, 255, 0.3)',backgroundColor:'rgba(255, 255, 255, 0.5)', height:56, width:56, alignItems:'center', justifyContent:'center', borderRadius:20}}>
                            <Image style={{height:24, width:24}} source={require('../assets/Images/kalp.png')}/>
                        </View>
                      </View>

                      <View style={{backgroundColor:'rgba(255, 255, 255, 0.2)',position:'absolute', bottom:0,width:'100%', borderBottomRightRadius:28,borderBottomLeftRadius:28}}>
                        <View style={{flexDirection:'row', padding:20,width:906, alignItems:'center'}}>
                        <View style={{width:700}}>
                          <div className='roboto' style={{color:'#fff', fontSize:24}}>{item.name}</div>
                          <div className='roboto's style={{color:'#fff', fontSize:18,color:'rgba(255, 255, 255, 0.4)'}}>{item.summary}</div>
                        </View>
                          <Pressable onPress={() => goBookDetail(item.id)} style={{height:88,width:179, backgroundColor:'#C7F14F',flexDirection:'row', justifyContent:'center', alignItems:'center', borderRadius:20}}>
                            <div className='roboto' style={{fontSize:16,color:'#111111', fontWeight:'bold'}}>Şimdi Oku</div>
                            <Image style={{height:24, width:24,marginLeft:15}} source={require('../assets/Images/book.png')}/>
                          </Pressable>
                        </View>
                      </View>
                    </ImageBackground>
              ))}
            </Fade>
       </div>

        <div className='roboto' style={{fontSize:24, color:'#fff', fontWeight:'bold',marginTop:20}}>Kaldığın Yerden Devam Et  </div>

        <div className='wrapper'>
          {mangaData.map((item, index) => (
            <View key={index} style={styles.item}>
              <Image source={{uri: item.image_url}} style={{ backgroundColor: '#D9D9D9', width: 162, height: 220 ,borderRadius:10}} />
              <div className='roboto' style={{fontSize:20,color:'#fff'}}>{item.author}</div>
              <div className='roboto' style={{fontSize:16,color:'#fff'}}>Bölüm: {item.bölüm}</div>
              
                <TouchableOpacity onPress={() =>goBookDetail(item.id)} style={{flexDirection:'row', backgroundColor:'#C7F14F', height:45, borderRadius:10, alignItems:'center',justifyContent:'center',marginTop:10}}>
                  <div className='roboto' style={{color:'#111111', fontSize:16, fontWeight:'bold' }}>Devam Et</div>
                  <Image style={{height:20,width:20,marginLeft:10}} source={require('../assets/Images/book.png')}/>
                </TouchableOpacity>
              
              

            </View>
          ))}
        </div>


        <div className='roboto' style={{fontSize:24, color:'#fff', fontWeight:'bold',marginTop:20}}>Son Eklenenler</div>

       
        <FlatList
          numColumns={5}
          data={mangaData}
          style={{width:906}}
          renderItem={({item}) => 
            <View  style={styles.item}>
              <Image source={{uri: item.image_url}} style={{ backgroundColor: '#D9D9D9', width: 162, height: 220 ,borderRadius:10}} />
              <div className='roboto' style={{fontSize:20,color:'#fff',maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item.name}</div>
              <div className='roboto' style={{fontSize:16,color:'#fff'}}>Bölüm: {item.bölüm}</div>
              <TouchableOpacity onPress={() =>goBookDetail(item.id)} style={{flexDirection:'row', backgroundColor:Colors.lightBlack, height:45, borderRadius:10, alignItems:'center',justifyContent:'center',marginTop:10}}>
                <div className='roboto' style={{color:Colors.green, fontSize:16, fontWeight:'bold' }}>Oku</div>
                <Image style={{height:20,width:20,marginLeft:10}} source={require('../assets/Images/yesilBook.png')}/>
              </TouchableOpacity>

            </View>
          }
        />
      </div>


      <View style={{marginRight:50}}>
        <View style={{ marginTop:30, marginLeft:30,flexDirection:'row'}}>
          <View style={{width:88, height:88, borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', alignItems:'center', justifyContent:'center'}}>
            <Image style={{height:28, width:28}} source={require('../assets/Images/noti.png')}/>
          </View>
          <View style={{width:257, height:88, marginLeft:10,borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
            <Image style={{backgroundColor:'#D9D9D9',height:40,width:40, borderRadius:100}}/>
            <View>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:16}}>Yuki Tanaka</div>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:14}}>info@gmail.com</div>
            </View>
            <Pressable onPress={handlePresss}>
              <Image source={require('../assets/Images/down.png')} style={{height:7,width:14,}}/>
            </Pressable>
          </View>
        </View>

        <View style={{backgroundColor:'blue', width:350,height:350,marginLeft:30,backgroundColor:Colors.lightBlack,marginTop:10,borderRadius:20, alignItems:'center', justifyContent:'center',}}>

            <Image style={{backgroundColor:'#e0e0e0', height:'90%', width:'90%',borderRadius:20}}/>
        </View>

        {modal ? (<View style={{backgroundColor:Colors.lightBlack,height:279, position:'absolute',width:250,right:4,top:105,padding:15}}>
            <View style={{borderWidth:0.5,borderColor:'#fff',marginTop:10,marginBottom:10}}></View>
            {/* <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/aile.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Aile Modu</Text>
            </View> */}
            {/* <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/karanlik.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Karanlık Mod</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/tema.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Tema</Text>
            </View>
            <Image source={require('../assets/Images/renkler.png')} style={{height:24,width:212}}/> */}

            <Pressable onPress={() => navigate('/Profile')} style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/profilim.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Profilim</Text>
            </Pressable>
            {/* <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/ayarlar.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Ayarlar</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/cikisYap.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Çıkış Yap</Text>
            </View> */}
        </View>): null}

        <View style={styles.bottoms}>
          <View style={{flexDirection:'row', justifyContent:'center',marginTop:20}}>
            <Image style={{height:28,width:28,marginRight:10}} source={require('../assets/Images/fires.png')}/>
            <div className='roboto' style={{fontSize:24,color:'#fff'}}>Popüler Seriler</div>
          </View>
            <div className='best' style={{margin:10, }}>
              {mangaData.map((item, index) =>
                <View style={{flexDirection:'row',alignItems:'center'}}>
                <Image source={require(`../assets/Images/${index + 1}.png`)} resizeMode='center' style={{height:113,width:53,}}/>
                <Image source={{uri:item.image_url}}  style={{height:100,width:72,backgroundColor:'#fff',borderRadius:10}}/>
                <View style={{width:190, marginLeft:10, justifyContent:'space-between', height:100}}>
                  <div className='roboto' style={{color:'white',fontSize:Sizes.regular}}>{item.name}</div>
                  <div className='name' style={{ fontSize:Sizes.smallText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>Genres: Dövüş Sanatları, Macera,Komedi</div>
                  {/* <div className='name' style={{ fontSize:Sizes.smallText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>Komedi</div> */}
                  <Image source={require('../assets/Images/stars.png')}  style={{height:24,width:160}}/>
                </View>
              </View>
              )}
            </div>
        </View>
      </View>

      

      
    </div>
    )
  }

  

  
  return (
    <body>
      {name > 906 && web()}
      {name < 906 && <Anasayfa/>}

    </body>
   
  );
}

              {/* <div indicatorStyle='green' className='detail' style={{height:420}}>
                {Mangas.map((item) => (
                  <View style={{marginTop:15,marginBottom:15, flexDirection:'row',alignItems:'center',justifyContent:'space-between'}} >
                  <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Image source={{uri: item.cover_image_url}} style={{ backgroundColor: '#D9D9D9', width: 190, height: 120 ,borderRadius:10}} />
                    <View style={{marginLeft:20}}>
                      <div className='roboto' style={{fontSize:Sizes.altTitle}}>Bölüm {item.chapter} : {item.title}</div>
                      <View style={{flexDirection:'row',marginTop:5}}>
                        <Image source={require('../assets/Images/takvim.png')} style={{width: 24, height: 24 ,marginRight:5}} />
                        <div className='name' style={{fontSize:Sizes.regular}}>1 hafta önce yayınlandı</div>
                      </View>
                    </View>
                  </View>
                  {item.buy ? (<Image source={require('../assets/Images/Alindi.png')} style={{width: 106, height: 46 ,borderRadius:10}} />): null}
                </View>
                ))}
            </div> */}

const styles = StyleSheet.create({
  container:{
  
    flexDirection:'row',
    backgroundColor:'rgba(17, 17, 17, 1)',
    height:Dimensions.get('window').height,
    width:Dimensions.get('window').width,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw'
      }
    })
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    scrollbarWidth: 'none', // Firefox için
    WebkitOverflowScrolling: 'touch', // Safari için
    '-ms-overflow-style': 'none' // IE11 için
  },

  item: {
    marginTop:10,
    marginRight:20
  },

  navBar:{
    backgroundColor:Colors.lightBlack,
    width:100,
    height:Dimensions.get('window').height/1.2,
    marginTop:20,
    borderRadius:20,
    marginLeft:150,

  },
  navIcons:{
    height:40,
    width:40,
    marginTop:25,
  },
  textInputs:{
    backgroundColor: 'rgba(26, 26, 26, 1)',
    width: 906,
    height: 80,
    borderRadius: 20,
    paddingLeft: 20,
    borderWidth:0
  },
  bottoms:{
    backgroundColor:'rgba(26, 26, 26, 1)',
    marginTop:15,
    height:490,
    width:350,
    marginLeft:30,
    borderRadius:20
  }
})

export default HomePage;
