import React,{useState} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList} from 'react-native'
import { useNavigate } from 'react-router-dom';


const Kitaplik = (props) => {

  const navigate = useNavigate();


  const fakeDate = [1,1,1,1,1,1,1,1]

  const [openModel, setOpenModal] = useState(false)

  const mangaData = [
    {
        id:1,
        name: "Elf Şovalyesi",
        image_url: require('../../assets/kapak/Juji.png'),
        type: "webtoon",
        bölüm: 360,
        author: "Juji",
        artist: "Juji",
        pop: "1",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
    },
    {
        id:2,
        name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
        image_url: require('../../assets/kapak/Küresel.png'),
        type: "webtoon",
        bölüm: 91,
        pop: "2",
        author: "Yorunezumi",
        artist: "Yorunezumi",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Dram" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
    },
    {
        id:3,
        name: "Isekai Fall of the Hero",
        image_url: require('../../assets/kapak/isekai.webp'),
        type: "webtoon",
        pop:"3",
        bölüm: 112,
        author: "patimatik",
        artist: "patimatik",
        categories: [
            { id: "1", name: "Aksiyon" },
            { id: "2", name: "Fantastik" },
            { id: "3", name: "Doğaüstü" }
        ],
        summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
    },
    {
      id:4,
      name: "Elf Şovalyesi",
      image_url: require('../../assets/kapak/Juji.png'),
      type: "webtoon",
      bölüm: 360,
      author: "Juji",
      artist: "Juji",
      pop: "1",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
  },
  {
      id:5,
      name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
      image_url: require('../../assets/kapak/Küresel.png'),
      type: "webtoon",
      bölüm: 91,
      pop: "2",
      author: "Yorunezumi",
      artist: "Yorunezumi",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Dram" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
  },
  {
      id:6,
      name: "Isekai Fall of the Hero",
      image_url: require('../../assets/kapak/isekai.webp'),
      type: "webtoon",
      pop:"3",
      bölüm: 112,
      author: "patimatik",
      artist: "patimatik",
      categories: [
          { id: "1", name: "Aksiyon" },
          { id: "2", name: "Fantastik" },
          { id: "3", name: "Doğaüstü" }
      ],
      summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
  },
  {
    id:7,
    name: "Elf Şovalyesi",
    image_url: require('../../assets/kapak/Juji.png'),
    type: "webtoon",
    bölüm: 360,
    author: "Juji",
    artist: "Juji",
    pop: "1",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Denji’nin babası ölünce büyük bir borç üstüne kalır ve bunu ödeyebilmesinin hiçbir yolu yoktur. Ancak kurtardığı Pochita isimli iblis köpek sayesinde iblis avcılığı yaparak borcunu azar azar kapatır. Pochita’nın testere güçleri iblislere karşı işe yarıyordur. Denji öldüğünde Pochita kendini feda edip onu diriltir. Fakat Denji artık eskisi gibi insan değildir. O artık Testere Adam!"
},
{
    id:8,
    name: "Küresel Cinsel Kültür Araştırma Enstitüsü",
    image_url: require('../../assets/kapak/Küresel.png'),
    type: "webtoon",
    bölüm: 91,
    pop: "2",
    author: "Yorunezumi",
    artist: "Yorunezumi",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Dram" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "Bir çocuk… “doğru ölüm” için savaşır. Zorluklar, pişmanlık, utanç… İnsanların hissettiği olumsuz duygular günlük hayatımızda gizlenen lanetlere dönüşür..."
},
{
    id:9,
    name: "Isekai Fall of the Hero",
    image_url: require('../../assets/kapak/isekai.webp'),
    type: "webtoon",
    pop:"3",
    bölüm: 112,
    author: "patimatik",
    artist: "patimatik",
    categories: [
        { id: "1", name: "Aksiyon" },
        { id: "2", name: "Fantastik" },
        { id: "3", name: "Doğaüstü" }
    ],
    summary: "10 yıl önce, “kapı” gerçek dünyayı canavar dünyasına bağlayıp açtıktan sonra..."
}
  ];



  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => { 
    navigate(`/Expolore`,{replace:true});
    setOpenModal(false) 
  };

  const goBookCase = () => {
    navigate(`/BookCase`,{replace:true}); 
    setOpenModal(false)
  };


  const handlePress = () => {
    navigate(`/Detail/${'anill'}`,{replace:true}); // 'Selam' sayfasına yönlendirme

  };

  const goBookDetail = (item) => { 
    navigate(`/BookDetail`,{ state: { id: item } });
    setOpenModal(false)
  };

  const goLikedPAge = () => {
    navigate(`/LikedPage`,{replace:true});
    setOpenModal(false) 
  };

  const goPurchase = () => { 
    navigate(`/Purchase`,{replace:true});
    setOpenModal(false) 
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true});
    setOpenModal(false)
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };



  function draweModalRender(){
    return(
      <SafeAreaView style={{flex:1,position:'absolute',backgroundColor:'#1A1A1A',width:'100%',height:'100%'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
          <TouchableOpacity style={{flexDirection:'row'}} onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/cik.png')}/>
            <Image style={{marginLeft:10,height:40,width:40}} source={require('../assets/popuplogo.png')}/>

          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={goNotification}>
              <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
            </TouchableOpacity>
            <Image style={{marginLeft:10, height:40,width:80}} source={require('../assets/giris.png')}/>
          </View>
        </View>
        <View style={{backgroundColor:'#1A1A1A', flex:1, alignItems:'center',marginTop:15}}>
          <View>
            <TouchableOpacity  onPress={goHomePage} style={{flexDirection:'row', alignItems:'center'}}>
              <Image style={{height:40,width:40}} source={require('../assets/anasayfa.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Anasayfa</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goExpolore} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kesfet.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Keşfet</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBookCase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kitaplik.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Kitaplık</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goLikedPAge} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/favoriler.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Favoriler</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goPurchase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/coin.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Coin Satın Al</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }


  return (
    <SafeAreaView style={{flex:1,backgroundColor:'black'}}>
      <ScrollView>

      
      <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image style={{height:40,width:90}} source={require('../assets/menu.png')}/>
        </TouchableOpacity>
        <View style={{flexDirection:'row'}}>
          <TouchableOpacity onPress={goNotification}>
            <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={goProfile}>
            <Image style={{marginLeft:10,height:40,width:128}} source={require('../assets/kullanici.png')}/>
          </TouchableOpacity>
        </View>
      </View>
     
      
      <View style={{marginLeft:10,marginTop:10}}>
        <Text style={{color:'#ffff', fontSize:22,fontWeight:'600'}}>Kitaplik</Text>
      </View>

      <FlatList
        data={mangaData}
        numColumns={2}
        renderItem={({item}) =>
          <View style={{margin:10,width:'45%'}}>
            <Image source={item.image_url} style={{backgroundColor:'#fff',height:200,width:'100%', borderRadius:10}}/>
            <Text numberOfLines={1} style={{fontSize:16,fontWeight:'600', color:'#fff',marginTop:3}}>{item.name}</Text>
            <Text style={{fontSize:14,fontWeight:'400', color:'#fff'}}>Alındı: 426,125,427 ve 9+  bölüm</Text>
            <TouchableOpacity onPress={() =>goBookDetail(item.id)} style={{backgroundColor:'#C7F14F',width:162, height:42,flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:3,borderRadius:10}}>
              <Text style={{fontSize:16, fontWeight:'500', color:'#111111',marginRight:10}}>Devam Et</Text>
              <Image source={require('../assets/book.png')}/>
            </TouchableOpacity>
          </View>
        }
      />

      </ScrollView>

      {openModel == true && draweModalRender()}
      
    </SafeAreaView>
  )
}

export default Kitaplik