// In App.js in a new project

import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Detail from './Pages/Detail';
import Expolore from './Pages/Expolore';
import BookCase from './Pages/BookCase';
import LikedPage from './Pages/LikedPage';
import BookDetail from './Pages/BookDetail';
import Purchase from './Pages/Purchase';
import Reading from './Pages/Reading';
import Profile from './Pages/Profile';
import Notification from './Pages/Notification';



function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/Profile' element={<Profile/>}/>
      <Route path='/Reading' element={<Reading/>}/>
      <Route path='/Purchase' element={<Purchase/>}/>
      <Route path='/BookDetail' element={<BookDetail/>}/>
      <Route path='/Expolore' element={<Expolore/>}/>
      <Route path='/BookCase' element={<BookCase/>}/>
      <Route path='/LikedPage' element={<LikedPage/>}/>
      <Route path='/Detail/:id' element={<Detail/>}/>
      <Route path='/Notification' element={<Notification/>}/>
    </Routes>
  );
}

export default App;