const Colors = {
  green: '#C7F14F',
  darkBlack:'#111111',
  lightBlack:'#1A1A1A',
  darkWhite:'#D9D9D9',
  white:'#FFFFFF',
}

const Sizes = {
  bigTitle:24,
  altTitle: 20,
  regular:18,
  smallTitle: 16,
  smallText:14,
}

const Fonts = {
  mono: 'Roboto-Regular',
  bold: 'Roboto-Bold',
  roboto:'roboto',

}

export {Colors, Sizes, Fonts}