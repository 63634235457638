// import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './App.css';
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable} from "react-native";
import { Fonts, Sizes, Colors } from '../Helper/Helper';
import { useNavigate } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
import Mangas from './Mangas.json'
import { TouchableOpacity } from 'react-native-web';
import Profil from '../src/screens/Profil'



function Profile(props) {

  const navigate = useNavigate();

  const [name, setName] = useState('Anill')
  const [currentPage, setCurrentPage] = useState('Kesfet');
  const [isHovered, setIsHovered] = useState(false);
  const [pressed, setPressed] = useState(false); // Başlangıçta false değeriyle başlatıyoruz
  const [mangaList, setMangaList] = useState([]);

  const [selectedManga, setSelectedManga] = useState(Mangas[0]);
  const [searchText, setSearchText] = useState('');
  const [filteredMangas, setFilteredMangas] = useState(Mangas);
  const [modal, setModal] = useState(false);




  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);


  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);




  



  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = Mangas.filter((manga) =>
      manga.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredMangas(filtered);
  };

  // useEffect(() => {
  //   if (mangaList.length > 0 && !selectedManga) {
  //     setSelectedManga(mangaList[0]);
  //   }
  // }, []);

  const handleMangaClick = (id) => {
    const clickedManga = Mangas.find(manga => manga.id === id);
    setSelectedManga(clickedManga);
    console.log(selectedManga)
  };


  const datas = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1] 

  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`); 
  };


  
  const goBookDetail = () => {
    console.log('Naber'); 
    navigate(`/BookDetail`); 
  };


  const goPurchase = () => {
    console.log('Naber'); 
    navigate(`/Purchase`); 
  };

  
  const handlePresss = () => {
    setModal(!modal);
  };




  useEffect(() => {
    const filteredMangas = Mangas.filter(manga =>
      manga.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredMangas(filteredMangas);
  }, [searchText]);

  function web(){
    return(
      <div className='profile' >
      {/* <Link className='custom-link' to={'/Selam'} >
        <Text>Selamlar</Text>
      </Link> */}
        <View style={styles.navBar}>
          <Image style={{height:40,width:40, alignSelf:'center',marginTop:30}} source={require('../assets/Images/logo.png')}/>
          <View style={{alignItems:'center',marginTop:120}}>
            <Pressable onPress={goHomePage}>
              <img className='navIcons' style={styles.navIcons} src={currentPage == 'Anasayfa' ? require('../assets/Images/anasayfa.png'): require('../assets/Images/dogruBeyazAnasayfa.png')}/>
            </Pressable>
            <Pressable onPress={goExpolore} >
              <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/yesilKesfet.png')}/>
            </Pressable>
            <Pressable onPress={goBookCase}>
              <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/satinalma.png')}/>
            </Pressable>
            <Pressable onPress={goLikedPAge}>
              <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/favori.png')}/>
            </Pressable>
            <Pressable onPress={goPurchase}>
              <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/magaza.png')}/>
            </Pressable>
          </View>
        </View>

        <View style={{width:1357,alignItems:'flex-end'}}>
                <View style={{marginRight:50}}>
                  <View style={{ marginTop:30, marginLeft:30,flexDirection:'row'}}>
                    <View style={{width:88, height:88, borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', alignItems:'center', justifyContent:'center'}}>
                      <Image style={{height:28, width:28}} source={require('../assets/Images/noti.png')}/>
                    </View>
                    <View style={{width:257, height:88, marginLeft:10,borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                      <Image style={{backgroundColor:'#D9D9D9',height:40,width:40, borderRadius:100}}/>
                      <View>
                        <div className='roboto' style={{color:'#FFFFFF', fontSize:16}}>Yuki Tanaka</div>
                        <div className='roboto' style={{color:'#FFFFFF', fontSize:14}}>info@gmail.com</div>
                      </View>
                      <Pressable onPress={handlePresss}>
                        <Image source={require('../assets/Images/down.png')} style={{height:7,width:14,}}/>
                      </Pressable>
                    </View>
                  </View>

                  {modal ? (<View style={{backgroundColor:Colors.lightBlack,height:279, position:'absolute',width:250,right:4,top:105,padding:15, borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
                    <View style={{borderWidth:0.5,borderColor:'#fff',marginTop:10,marginBottom:10}}></View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/aile.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Aile Modu</Text>
                    </View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/karanlik.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Karanlık Mod</Text>
                    </View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/tema.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Tema</Text>
                    </View>
                    <Image source={require('../assets/Images/renkler.png')} style={{height:24,width:212}}/>

                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/profilim.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Profilim</Text>
                    </View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/ayarlar.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Ayarlar</Text>
                    </View>
                    <View style={{flexDirection:'row',marginTop:10}}>
                      <Image source={require('../assets/Images/cikisYap.png')} style={{height:24,width:24}}/>
                      <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Çıkış Yap</Text>
                    </View>
                  </View>): null}



        
              </View>
            <View style={{backgroundColor:Colors.lightBlack,marginRight:50,marginTop:15,width:1300,height:695,borderRadius:20,alignItems:'center'}}>
              <div className='roboto' style={{color:'#fff',fontSize:36, fontWeight:'bold',margin:10}}>Profil</div>
              <Image style={{height:110,width:110,borderRadius:200,backgroundColor:'#fff'}}/>
              <View style={{width:252,height:36, backgroundColor:'#2A2A2A', flexDirection:'row', alignItems:'center', justifyContent:'center', borderRadius:25,margin:10}}>
                <Text style={{fontSize:16,color:'#fff'}}>Profil Fotoğrafını Değiştir</Text>
                <Image source={require('../assets/Images/changePhoto.png')} style={{height:20,width:20,marginLeft:15,}}/>
              </View>

              <View style={{backgroundColor:'#2A2A2A', width:574, height:450,borderRadius:20}}>
                <View style={{margin:25}}>
                  <Text style={{color:'#fff', fontSize:16,margin:10}}>Görünen Ad</Text>
                  <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderWidth:1, borderColor:'#3b3b3b',padding:10,borderRadius:20}}>
                    <Text style={{color:'#fff', fontSize:22}}>Yuki Tanaka</Text>
                    <Image source={require('../assets/Images/pencil.png')} style={{height:24,width:24,marginRight:10}}/>
                  </View>
                  <Text style={{color:'#fff', fontSize:16,margin:10}}>Kullanıcı Adı</Text>
                  <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderWidth:1, borderColor:'#3b3b3b',padding:10,borderRadius:20}}>
                    <Text style={{color:'#fff', fontSize:22}}>Yuki Tanaka</Text>
                    <Image source={require('../assets/Images/pencil.png')} style={{height:24,width:24,marginRight:10}}/>
                  </View>
                  <Text style={{color:'#fff', fontSize:16,margin:10}}>E-Mail</Text>
                  <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderWidth:1, borderColor:'#3b3b3b',padding:10,borderRadius:20}}>
                    <Text style={{color:'#fff', fontSize:22}}>info@gmail.com</Text>
                  </View>
                  <Text style={{color:'#fff', fontSize:16,margin:10}}>Şifre</Text>
                  <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', borderWidth:1, borderColor:'#3b3b3b',padding:10,borderRadius:20}}>
                    <Text style={{color:'#fff', fontSize:22}}>************</Text>
                    <View style={{flexDirection:'row'}}>
                      <Image source={require('../assets/Images/pencil.png')} style={{height:24,width:24,marginRight:5}}/>
                      <Image source={require('../assets/Images/password.png')} style={{height:24,width:24,marginRight:10}}/>
                    </View>
                  </View>
                </View>
              </View>
            </View>
        </View>
      </div>
    )
  }

  
  return (
    <body>
     {name > 906 && web()}
     {name < 906 && <Profil/>}

    </body>
   
  );
}

const styles = StyleSheet.create({
  container:{
  
    flexDirection:'row',
    backgroundColor:'rgba(17, 17, 17, 1)',
    height:Dimensions.get('window').height,
    width:Dimensions.get('window').width,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw'
      }
    })
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    scrollbarWidth: 'none', // Firefox için
    WebkitOverflowScrolling: 'touch', // Safari için
    '-ms-overflow-style': 'none' // IE11 için
  },

  item: {
    marginTop:10,
    marginRight:20
  },

  navBar:{
    backgroundColor:Colors.lightBlack,
    width:100,
    height:Dimensions.get('window').height/1.2,
    marginTop:20,
    borderRadius:20,
    marginLeft:150,
    marginRight:30

  },
  navIcons:{
    height:40,
    width:40,
    marginTop:25,
  },
  textInputs:{
    backgroundColor: 'rgba(26, 26, 26, 1)',
    width: 906,
    height: 80,
    borderRadius: 20,
    paddingLeft: 20,
    borderWidth:0,
    color:'#fff'
  },
  bottoms:{
    backgroundColor:'rgba(26, 26, 26, 1)',
    marginTop:15,
    height:490,
    width:350,
    marginLeft:30,
    borderRadius:20
  },
  desc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    padding:5
  },
  rightDesc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    marginLeft:10 ,
    padding:5
  },
})

export default Profile;
