import React,{useState, useEffect} from 'react'
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable} from "react-native";
import { useNavigate } from 'react-router-dom';
import Bildirimler from '../src/screens/Bildirimler'


function Notification() {

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);



  const goHomePage = () => {
    navigate(`/`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    navigate(`/Expolore`,{replace:true}); 
  };


  const handlePress = () => {
    navigate(`/Detail/${'anill'}`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goBookCase = () => {
    navigate(`/BookCase`,{replace:true});
    setOpenModal(false)

  };

  const goLikedPAge = () => {
    navigate(`/LikedPage`,{replace:true});
    setOpenModal(false)
  };

  
  const goBookDetail = () => { 
    navigate(`/BookDetail`,{replace:true});
    setOpenModal(false)
  };

  const goPurchase = () => {
    navigate(`/Purchase`,{replace:true});
    setOpenModal(false)
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true}); 
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };




  const [name, setName] = useState('Anill')

  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);

  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);



  function web(){
    return(
      <div>Bildirimler Sayfası Geliştirilmektedir.</div>
    )
  }

  return (
    <div>
      {name > 906 && web()}
      {name < 906 && <Bildirimler/>}
    </div>
  )
}

export default Notification