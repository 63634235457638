import React,{useState} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList, StatusBar, StyleSheet,Dimensions,LogBox} from 'react-native'
import { useNavigate } from 'react-router-dom';


LogBox.ignoreAllLogs(true);


const Oku = (props) => {

  LogBox.ignoreAllLogs(true);



  const navigate = useNavigate();

  const goBookDetail = (item) => {
    console.log('Naber'); 
    navigate(`/BookDetail`,{ state: { id: 1 } }); 
  };






  const updatedData = [
    { id: 1, url: require('../assets/yeni/1.png') },
    { id: 2, url: require('../assets/yeni/2.png') },
    { id: 3, url: require('../assets/yeni/3.png') },
    { id: 4, url: require('../assets/yeni/4.png') },
    { id: 5, url: require('../assets/yeni/5.png') },
    { id: 6, url: require('../assets/yeni/6.png') },
    { id: 7, url: require('../assets/yeni/7.png') },
    { id: 8, url: require('../assets/yeni/8.png') },
    { id: 9, url: require('../assets/yeni/9.png') },
    { id: 10, url: require('../assets/yeni/10.png') },
    { id: 11, url: require('../assets/yeni/11.png') },
    { id: 12, url: require('../assets/yeni/12.png') },
    { id: 13, url: require('../assets/yeni/13.png') },
    { id: 14, url: require('../assets/yeni/14.png') },
    { id: 15, url: require('../assets/yeni/15.png') },
    { id: 16, url: require('../assets/yeni/16.png') },
    { id: 17, url: require('../assets/yeni/17.png') },
    { id: 18, url: require('../assets/yeni/18.png') },
    { id: 19, url: require('../assets/yeni/19.png') },
    { id: 20, url: require('../assets/yeni/20.png') },
    { id: 21, url: require('../assets/yeni/21.png') },
    { id: 22, url: require('../assets/yeni/22.png') },
    { id: 23, url: require('../assets/yeni/23.png') },
    { id: 24, url: require('../assets/yeni/24.png') },
    { id: 25, url: require('../assets/yeni/25.png') },
    { id: 26, url: require('../assets/yeni/26.png') },
    { id: 27, url: require('../assets/yeni/27.png') },
    { id: 28, url: require('../assets/yeni/28.png') },
    { id: 29, url: require('../assets/yeni/29.png') },
    { id: 30, url: require('../assets/yeni/30.png') },
    { id: 31, url: require('../assets/yeni/31.png') },
    { id: 32, url: require('../assets/yeni/32.png') },
    { id: 33, url: require('../assets/yeni/33.png') },
    { id: 34, url: require('../assets/yeni/34.png') },
    { id: 35, url: require('../assets/yeni/35.png') },
    { id: 36, url: require('../assets/yeni/36.png') },
    { id: 37, url: require('../assets/yeni/37.png') },
    { id: 38, url: require('../assets/yeni/38.png') },
    { id: 39, url: require('../assets/yeni/39.png') },
    { id: 40, url: require('../assets/yeni/40.png') },
    { id: 41, url: require('../assets/yeni/41.png') },
    { id: 42, url: require('../assets/yeni/42.png') },
    { id: 43, url: require('../assets/yeni/43.png') },
    { id: 44, url: require('../assets/yeni/44.png') },
    { id: 45, url: require('../assets/yeni/45.png') },
    { id: 46, url: require('../assets/yeni/46.png') },
    { id: 47, url: require('../assets/yeni/47.png') },
    { id: 48, url: require('../assets/yeni/48.png') },
    { id: 49, url: require('../assets/yeni/49.png') },
    { id: 50, url: require('../assets/yeni/50.png') },
    { id: 51, url: require('../assets/yeni/51.png') },
    { id: 52, url: require('../assets/yeni/52.png') },
    { id: 53, url: require('../assets/yeni/53.png') },
    { id: 54, url: require('../assets/yeni/54.png') },
    { id: 55, url: require('../assets/yeni/55.png') },
    { id: 56, url: require('../assets/yeni/56.png') },
    { id: 57, url: require('../assets/yeni/57.png') },
    { id: 58, url: require('../assets/yeni/58.png') },
    { id: 59, url: require('../assets/yeni/59.png') },
    { id: 60, url: require('../assets/yeni/60.png') },
    { id: 61, url: require('../assets/yeni/61.png') },
    { id: 62, url: require('../assets/yeni/62.png') },
    { id: 63, url: require('../assets/yeni/63.png') },
    { id: 64, url: require('../assets/yeni/64.png') },
    { id: 65, url: require('../assets/yeni/65.png') },
    { id: 66, url: require('../assets/yeni/66.png') },
    { id: 67, url: require('../assets/yeni/67.png') },
    { id: 68, url: require('../assets/yeni/68.png') },
    { id: 69, url: require('../assets/yeni/69.png') },
    { id: 70, url: require('../assets/yeni/70.png') },
    { id: 71, url: require('../assets/yeni/71.png') },
    { id: 72, url: require('../assets/yeni/72.png') },
    { id: 73, url: require('../assets/yeni/73.png') },
    { id: 74, url: require('../assets/yeni/74.png') },
    { id: 75, url: require('../assets/yeni/75.png') },
    { id: 76, url: require('../assets/yeni/76.png') },
    { id: 77, url: require('../assets/yeni/77.png') },
    { id: 78, url: require('../assets/yeni/78.png') },
    { id: 79, url: require('../assets/yeni/79.png') },
    { id: 80, url: require('../assets/yeni/80.png') },
    { id: 81, url: require('../assets/yeni/81.png') },
    { id: 82, url: require('../assets/yeni/82.png') },
    { id: 83, url: require('../assets/yeni/83.png') },
    { id: 84, url: require('../assets/yeni/84.png') },
    { id: 85, url: require('../assets/yeni/85.png') },
    { id: 86, url: require('../assets/yeni/86.png') },
    { id: 87, url: require('../assets/yeni/87.png') },
    { id: 88, url: require('../assets/yeni/88.png') },
    { id: 89, url: require('../assets/yeni/89.png') },
    { id: 90, url: require('../assets/yeni/90.png') },
    { id: 91, url: require('../assets/yeni/91.png') },
    { id: 92, url: require('../assets/yeni/92.png') },
    { id: 93, url: require('../assets/yeni/93.png') },
    { id: 94, url: require('../assets/yeni/94.png') },
    { id: 95, url: require('../assets/yeni/95.png') },
    { id: 96, url: require('../assets/yeni/96.png') },
    { id: 97, url: require('../assets/yeni/97.png') },
    { id: 98, url: require('../assets/yeni/98.png') },
    { id: 99, url: require('../assets/yeni/99.png') },
    { id: 100, url: require('../assets/yeni/100.png') },
    { id: 101, url: require('../assets/yeni/101.png') },
    { id: 102, url: require('../assets/yeni/102.png') },
    { id: 103, url: require('../assets/yeni/103.png') },
    { id: 104, url: require('../assets/yeni/104.png') },
    { id: 105, url: require('../assets/yeni/105.png') },
    { id: 106, url: require('../assets/yeni/106.png') },
    { id: 107, url: require('../assets/yeni/107.png') },
    { id: 108, url: require('../assets/yeni/108.png') },
    { id: 109, url: require('../assets/yeni/109.png') },
    { id: 110, url: require('../assets/yeni/110.png') },
    { id: 111, url: require('../assets/yeni/111.png') },
    { id: 112, url: require('../assets/yeni/112.png') },
    { id: 113, url: require('../assets/yeni/113.png') },
    { id: 114, url: require('../assets/yeni/114.png') },
    { id: 115, url: require('../assets/yeni/115.png') },
    { id: 116, url: require('../assets/yeni/116.png') },
    { id: 117, url: require('../assets/yeni/117.png') },
    { id: 118, url: require('../assets/yeni/118.png') },
    { id: 119, url: require('../assets/yeni/119.png') },
    { id: 120, url: require('../assets/yeni/120.png') },
    { id: 121, url: require('../assets/yeni/121.png') },
    { id: 122, url: require('../assets/yeni/122.png') },
    { id: 123, url: require('../assets/yeni/123.png') },
    { id: 124, url: require('../assets/yeni/124.png') },
    { id: 125, url: require('../assets/yeni/125.png') },
    { id: 126, url: require('../assets/yeni/126.png') },
    { id: 127, url: require('../assets/yeni/127.png') },
    { id: 128, url: require('../assets/yeni/128.png') },
    { id: 129, url: require('../assets/yeni/129.png') },
    { id: 130, url: require('../assets/yeni/130.png') },
    { id: 131, url: require('../assets/yeni/131.png') },
    { id: 132, url: require('../assets/yeni/132.png') },
    { id: 133, url: require('../assets/yeni/133.png') },
    { id: 134, url: require('../assets/yeni/134.png') },
    { id: 135, url: require('../assets/yeni/135.png') },
    { id: 136, url: require('../assets/yeni/136.png') },
    { id: 137, url: require('../assets/yeni/137.png') },
    { id: 138, url: require('../assets/yeni/138.png') },
    { id: 139, url: require('../assets/yeni/139.png') },
    { id: 140, url: require('../assets/yeni/140.png') },
    { id: 141, url: require('../assets/yeni/141.png') },
    { id: 142, url: require('../assets/yeni/142.png') },
    { id: 143, url: require('../assets/yeni/143.png') },
    { id: 144, url: require('../assets/yeni/144.png') },
    { id: 145, url: require('../assets/yeni/145.png') },
    { id: 146, url: require('../assets/yeni/146.png') },
    { id: 147, url: require('../assets/yeni/147.png') },
    { id: 148, url: require('../assets/yeni/148.png') },
    { id: 149, url: require('../assets/yeni/149.png') },
    { id: 150, url: require('../assets/yeni/150.png') },
    { id: 151, url: require('../assets/yeni/151.png') },
    { id: 152, url: require('../assets/yeni/152.png') },
    { id: 153, url: require('../assets/yeni/153.png') },
    { id: 154, url: require('../assets/yeni/154.png') },
    { id: 155, url: require('../assets/yeni/155.png') },
    { id: 156, url: require('../assets/yeni/156.png') },
    { id: 157, url: require('../assets/yeni/157.png') },
    { id: 158, url: require('../assets/yeni/158.png') },
    { id: 159, url: require('../assets/yeni/159.png') },
    { id: 160, url: require('../assets/yeni/160.png') },
  ];
  


  const [openModel, setOpenModal] = useState(false)


  const comments =  [
    {
      "username": "movieBuff92",
      "time": 15,
      "description": "A gripping tale of adventure and mystery as a young hero embarks on a journey to uncover ancient secrets hidden deep within a forgotten world. With breathtaking visuals and a captivating storyline, this film will keep you on the edge of your seat."
    },
    {
      "username": "cinemaLover88",
      "time": 8,
      "description": "A heartwarming story of love and friendship."
    },
    {
      "username": "filmFanatic77",
      "time": 22,
      "description": "An epic saga of war and honor set in a world of magic and fantasy. Follow the journey of a warrior destined to change the fate of his people."
    },
    {
      "username": "screenCritic21",
      "time": 10,
      "description": "A thrilling chase through the streets of a dystopian future, where every moment counts and survival is not guaranteed."
    },
    {
      "username": "blockbusterFan23",
      "time": 19,
      "description": "A light-hearted romantic comedy that will make you laugh and cry."
    },
    {
      "username": "actionHero45",
      "time": 13,
      "description": "Explosions, high-speed chases, and non-stop action in this adrenaline-pumping blockbuster."
    }
  ]


  function draweModalRender(){
    return(
      <View style={{backgroundColor:'#1A1A1A',borderRadius:20, position:'absolute', height:'70%',bottom:0,width:'100%'}}>
      <View style={{margin:10}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',alignItems:'center',margin:10}}>
          <Text style={{fontSize:22,fontWeight:'600',color:'#fff',marginBottom:15}}>Comments</Text>
          <TouchableOpacity onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/exit.png')}/>
          </TouchableOpacity>
        </View>
        <ScrollView>
          {comments.map((item) =>
            <View style={{}}>
            <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
              <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                <Image style={{height:35,width:35,backgroundColor:'#fff',borderRadius:100}}/>
                <Text style={{fontSize:16,fontWeight:'500', color:'#fff',marginLeft:10}}>{item.username}</Text>
              </View>
              <View style={{flexDirection:'row'}}>
                <Image source={require('../assets/saat.png')}/>
                <Text style={{fontSize:16,fontWeight:'500', color:'#cccccc',marginLeft:5}}>{item.time} Saat</Text>
                <Image style={{marginLeft:20}} source={require('../assets/dot.png')}/>
              </View>
            </View>
            <Text style={{marginTop:10,fontSize:14,fontWeight:'400', color:'#cccc'}}>{item.description}</Text>
          </View>
          )}
        </ScrollView>

      </View>

    </View>
    )
  }

  const windowHeight = Dimensions.get('window').height;
  const windowWidth = Dimensions.get('window').width;


  return (
    <SafeAreaView style={{backgroundColor:'black',flex:1}}>      
      <View style={{flexDirection:'row', margin:10}}>
        <TouchableOpacity onPress={goBookDetail}>
          <Image style={{height:40,width:40}} source={require('../assets/back.png')}/>
        </TouchableOpacity>
        <Text style={{fontSize:28, fontWeight:'600',color:'#fff',marginLeft:10}}>Yuan Zun</Text>
      </View>
   

         <FlatList
          data={updatedData}
          renderItem={({item}) => 
            <Image 
                source={item.url} 
                style={{width: windowWidth,height: windowHeight/2,}}
                resizeMode='contain'
            />
          } 
         />

         <View style={{backgroundColor:'#1A1A1A',height:100, flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
          <TouchableOpacity style={{marginLeft:20,backgroundColor:'#C7F14F', height:40, width:108, flexDirection:'row',alignItems:'center', justifyContent:'center',borderRadius:50}}>
            <Image style={{height:20,width:20}} source={require('../assets/once.png')} />
            <Text style={{fontSize:14,fontWeight:'500',color:'black'}}>Önceki</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => setOpenModal(true)}>
            <Image style={{height:40,width:48}} source={require('../assets/chat.png')} />
          </TouchableOpacity>

          <TouchableOpacity style={{marginRight:20,backgroundColor:'#C7F14F', height:40, width:108, flexDirection:'row',alignItems:'center', justifyContent:'center',borderRadius:50}}>
            <Text style={{fontSize:14,fontWeight:'500',color:'black'}}>Sonraki</Text>
            <Image style={{height:20,width:20}} source={require('../assets/sonra.png')} />
          </TouchableOpacity>
         </View>

      {openModel == true && draweModalRender()}
      
    </SafeAreaView>
  )
}



export default Oku