import React,{useState} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList, StatusBar, Dimensions} from 'react-native'
import { useNavigate } from 'react-router-dom';


const Bildirimler = (props) => {

  const navigate = useNavigate();


  const fakeData = [
    {
      "chapterNumber": 101,
     
    },
    {
      "chapterNumber": 102,
     
    },
    {
      "chapterNumber": 103,
      
    },
    {
      "chapterNumber": 104,
      
    },
    {
      "chapterNumber": 105,
     
    },
    {
      "chapterNumber": 106,
      
    }
  ];

  

  const [openModel, setOpenModal] = useState(false)

  const goHomePage = () => {
    navigate(`/`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    navigate(`/Expolore`,{replace:true}); 
  };


  const handlePress = () => {
    navigate(`/Detail/${'anill'}`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goBookCase = () => {
    navigate(`/BookCase`,{replace:true});
    setOpenModal(false)

  };

  const goLikedPAge = () => {
    navigate(`/LikedPage`,{replace:true});
    setOpenModal(false)
  };

  
  const goBookDetail = () => { 
    navigate(`/BookDetail`,{replace:true});
    setOpenModal(false)
  };

  const goPurchase = () => {
    navigate(`/Purchase`,{replace:true});
    setOpenModal(false)
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true}); 
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };



  function draweModalRender(){
    return(
      <SafeAreaView style={{flex:1,position:'absolute',backgroundColor:'#1A1A1A',width:'100%',height:'100%'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
          <TouchableOpacity style={{flexDirection:'row'}} onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/cik.png')}/>
            <Image style={{marginLeft:10,height:40,width:40}} source={require('../assets/popuplogo.png')}/>

          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity>
              <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
            </TouchableOpacity>
            <Image style={{marginLeft:10, height:40,width:80}} source={require('../assets/giris.png')}/>
          </View>
        </View>
        <View style={{backgroundColor:'#1A1A1A', flex:1, alignItems:'center',marginTop:15}}>
          <View>
            <TouchableOpacity  onPress={goHomePage} style={{flexDirection:'row', alignItems:'center'}}>
              <Image style={{height:40,width:40}} source={require('../assets/anasayfa.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Anasayfa</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goExpolore} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kesfet.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Keşfet</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBookCase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kitaplik.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Kitaplık</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goLikedPAge} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/favoriler.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Favoriler</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goPurchase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/coin.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Coin Satın Al</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }


  return (
    <SafeAreaView style={{flex:1,backgroundColor:'black'}}>
      <StatusBar backgroundColor={'black'} barStyle={'light-content'}/>
      <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image style={{height:40,width:90}} source={require('../assets/menu.png')}/>
        </TouchableOpacity>
        <View style={{flexDirection:'row'}}>
          <TouchableOpacity>
            <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={goProfile}>
            <Image style={{marginLeft:10,height:40,width:128}} source={require('../assets/kullanici.png')}/>
          </TouchableOpacity>
        </View>
      </View>

      <View style={{height:Dimensions.get('window').height/1,margin:10,backgroundColor:'#1A1A1A',borderRadius:20}}>
        <Text style={{fontSize:24,fontWeight:'600',marginLeft:20, color:'#fff',marginTop:10,marginBottom:25}}>Bildirimler</Text>
        <ScrollView>
          {fakeData.map((item) =>
            <View style={{flexDirection:'row',backgroundColor:'#2A2A2A',marginLeft:20,marginRight:20,marginTop:15,alignItems:'center',padding:10,borderRadius:20}}>
              <Text style={{fontSize:14, fontWeight:'400', color:'#fff',marginLeft:10}}>Mortail Peak {item.chapterNumber}. Bölüm yayınlandı!</Text>
              <Image style={{marginLeft:10, height:20,width:20}} source={require('../assets/bildirimbook.png')}/>
            </View>
          )}
        </ScrollView>
      </View>


      
      


     
      
     


      {openModel == true && draweModalRender()}
      
    </SafeAreaView>
  )
}

export default Bildirimler