import React,{useState} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList, StatusBar, Dimensions} from 'react-native'
import { useNavigate } from 'react-router-dom';


const Profil = (props) => {

  const fakeData = [
    {
      "chapterNumber": 101,
     
    },
    {
      "chapterNumber": 102,
     
    },
    {
      "chapterNumber": 103,
      
    },
    {
      "chapterNumber": 104,
      
    },
    {
      "chapterNumber": 105,
     
    },
    {
      "chapterNumber": 106,
      
    }
  ];


  const navigate = useNavigate();

  
  const goHomePage = () => {
    navigate(`/`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    navigate(`/Expolore`,{replace:true}); 
  };


  const handlePress = () => {
    navigate(`/Detail/${'anill'}`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goBookCase = () => {
    navigate(`/BookCase`,{replace:true});
    setOpenModal(false)

  };

  const goLikedPAge = () => {
    navigate(`/LikedPage`,{replace:true});
    setOpenModal(false)
  };

  
  const goBookDetail = () => { 
    navigate(`/BookDetail`,{replace:true});
    setOpenModal(false)
  };

  const goPurchase = () => {
    navigate(`/Purchase`,{replace:true});
    setOpenModal(false)
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true});
    setOpenModal(false)
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };





  

  const [openModel, setOpenModal] = useState(false)


  function draweModalRender(){
    return(
      <SafeAreaView style={{flex:1,position:'absolute',backgroundColor:'#1A1A1A',width:'100%',height:'100%'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
          <TouchableOpacity style={{flexDirection:'row'}} onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/cik.png')}/>
            <Image style={{marginLeft:10,height:40,width:40}} source={require('../assets/popuplogo.png')}/>

          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={goNotification}>
              <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
            </TouchableOpacity>
            <Image style={{marginLeft:10,height:40,width:80}} source={require('../assets/giris.png')}/>
          </View>
        </View>
        <View style={{backgroundColor:'#1A1A1A', flex:1, alignItems:'center',marginTop:15}}>
          <View>
            <TouchableOpacity  onPress={goHomePage} style={{flexDirection:'row', alignItems:'center'}}>
              <Image style={{height:40,width:40}} source={require('../assets/anasayfa.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Anasayfa</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goExpolore} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kesfet.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Keşfet</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBookCase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kitaplik.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Kitaplık</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goLikedPAge} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/favoriler.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Favoriler</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goPurchase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/coin.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Coin Satın Al</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }


  return (
    <SafeAreaView style={{flex:1,backgroundColor:'black'}}>
      <StatusBar backgroundColor={'black'} barStyle={'light-content'}/>
      <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image style={{height:40,width:90}} source={require('../assets/menu.png')}/>
        </TouchableOpacity>
        <View style={{flexDirection:'row'}}>
          <TouchableOpacity onPress={goNotification}>
            <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
          </TouchableOpacity>
          <TouchableOpacity >
            <Image style={{marginLeft:10,height:40,width:128}} source={require('../assets/kullanici.png')}/>
          </TouchableOpacity>
        </View>
      </View>

        <Text style={{fontSize:22,fontWeight:'600',color:'#fff',textAlign:'center',marginTop:30}}>Profil</Text>
        <View style={{alignItems:'center',marginTop:20,}}>
          <Image style={{height:96,width:96,backgroundColor:'#fff',borderRadius:100}}/>
        </View>
        <View style={{backgroundColor:'#2A2A2A', flexDirection:'row', alignSelf:'center',height:36,width:231,alignItems:'center',justifyContent:'center',borderRadius:20,marginTop:20}}>
          <Text style={{color:'#fff',fontSize:14,fontWeight:'400'}}>Profil Fotoğrafını Değiştir</Text>
          <Image style={{marginLeft:10, height:20,width:20}} source={require('../assets/profilresim.png')}/>
        </View>
      <View style={{height:Dimensions.get('window').height/1,margin:10,marginTop:25,backgroundColor:'#1A1A1A',borderRadius:20,}}>
        <View style={{marginLeft:20,marginRight:20}}>
          <Text style={{fontSize:14,fontWeight:'400',color:'#fff',marginTop:15,marginLeft:10}}>Görünen Ad</Text>

          <View style={{borderWidth:1,borderColor:'gray',padding:8, borderRadius:20, flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
            <Text style={{fontSize:16,fontWeight:'400', color:'#fff'}}>Yuki Tanaka</Text>
            <Image style={{height:20,width:20}} source={require('../assets/edit.png')}/>
          </View>

          <Text style={{fontSize:14,fontWeight:'400',color:'#fff',marginTop:15,marginLeft:10}}>Kullanıcı Adı</Text>

          <View style={{borderWidth:1,borderColor:'gray',padding:8, borderRadius:20, flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
            <Text style={{fontSize:16,fontWeight:'400', color:'#fff'}}>Yuki Tanaka</Text>
            <Image style={{height:20,width:20}} source={require('../assets/edit.png')}/>
          </View>

          <Text style={{fontSize:14,fontWeight:'400',color:'#fff',marginTop:15,marginLeft:10}}>E-Mail</Text>

          <View style={{borderWidth:1,borderColor:'gray',padding:8, borderRadius:20, flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
            <Text style={{fontSize:16,fontWeight:'400', color:'#fff'}}>info@gmail.com</Text>
            <Image style={{height:20,width:20}} source={require('../assets/edit.png')}/>
          </View>

          <Text style={{fontSize:14,fontWeight:'400',color:'#fff',marginTop:15,marginLeft:10}}>Şifre</Text>

          <View style={{borderWidth:1,borderColor:'gray',padding:8, borderRadius:20, flexDirection:'row', justifyContent:'space-between',marginTop:10}}>
            <Text style={{fontSize:16,fontWeight:'400', color:'#fff'}}>********</Text>
            <Image style={{height:20,width:20}} source={require('../assets/edit.png')}/>
          </View>

        </View>
      </View>


      
      


     
      
     


      {openModel == true && draweModalRender()}
      
    </SafeAreaView>
  )
}

export default Profil