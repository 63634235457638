// import logo from './logo.svg';
import { Link } from 'react-router-dom';
import './App.css';
import { Text, View, StyleSheet , Dimensions, Image, ImageBackground, TextInput,Platform, FlatList, ScrollView,Pressable} from "react-native";
import { Fonts, Sizes, Colors } from '../Helper/Helper';
import { useNavigate } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
import Mangas from './Mangas.json'
import Coin from '../src/screens/Coin'



function Purchase(props) {

  const navigate = useNavigate();

  const [name, setName] = useState('Anill')
  const [currentPage, setCurrentPage] = useState('Kesfet');
  const [isHovered, setIsHovered] = useState(false);
  const [pressed, setPressed] = useState(false); // Başlangıçta false değeriyle başlatıyoruz
  const [mangaList, setMangaList] = useState([]);

  const [selectedManga, setSelectedManga] = useState(Mangas[0]);
  const [searchText, setSearchText] = useState('');
  const [filteredMangas, setFilteredMangas] = useState(Mangas);

  const [containerWidth, setContainerWidth] = useState(906);
  const [containerHeight, setContainerHeight] = useState(437);

  // Ekran boyutlarını güncelle
  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get('window').width;
      // Ana kutu genişliğini ekran genişliğine göre ayarla
      setContainerWidth(screenWidth > 906 ? 906 : '100%'); // Ekran genişliğinden büyükse 906, değilse %95'i
      setContainerHeight(containerWidth * 0.5); // Yüksekliği genişliğe göre ayarla
      
      setName(screenWidth)
      console.log(name)
    };

    updateLayout(); // Başlangıçta kontrol et
    const subscription = Dimensions.addEventListener('change', updateLayout); // Boyut değişikliklerini dinle

    return () => {
      subscription?.remove();
    };
  }, [containerWidth,name]);






  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = Mangas.filter((manga) =>
      manga.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredMangas(filtered);
  };

  // useEffect(() => {
  //   if (mangaList.length > 0 && !selectedManga) {
  //     setSelectedManga(mangaList[0]);
  //   }
  // }, []);

  const handleMangaClick = (id) => {
    const clickedManga = Mangas.find(manga => manga.id === id);
    setSelectedManga(clickedManga);
    console.log(selectedManga)
  };


  const datas = [1,1,1,1] 

  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`); 
  };


  const [modal, setModal] = useState(false);

  const handlePresss = () => {
    setModal(!modal);
  };



  useEffect(() => {
    const filteredMangas = Mangas.filter(manga =>
      manga.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredMangas(filteredMangas);
  }, [searchText]);



  function web(){
    return(
      <div className='buy' >
      {/* <Link className='custom-link' to={'/Selam'} >
        <Text>Selamlar</Text>
      </Link> */}
       <View style={styles.navBar}>
        <Image style={{height:40,width:40, alignSelf:'center',marginTop:30}} source={require('../assets/Images/logo.png')}/>
        <View style={{alignItems:'center',marginTop:120}}>
          <Pressable onPress={goHomePage}>
            <img className='navIcons' style={styles.navIcons} src={currentPage == 'Anasayfa' ? require('../assets/Images/anasayfa.png'): require('../assets/Images/dogruBeyazAnasayfa.png')}/>
          </Pressable>
          <Pressable onPress={goExpolore} >
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/kesfet.png')}/>
          </Pressable>
          <Pressable onPress={goBookCase}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/satinalma.png')}/>
          </Pressable>
          <Pressable onPress={goLikedPAge}>
            <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/favori.png')}/>
          </Pressable>
          <img className='navIcons' style={styles.navIcons} src={require('../assets/Images/deneme.png')}/>
        </View>
      </View>


     
     <div className='middle-section' style={{marginTop:30,marginLeft:30}}>
        {/* <TextInput value={searchText} onChangeText={setSearchText} placeholderTextColor='white' style={styles.textInputs}  placeholder='Bir şey arayın...'/> */}
       
        <div style={{ display: 'flex', alignItems: 'center' , justifyContent:'space-between',backgroundColor:Colors.lightBlack,borderRadius:20}}>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Bir şey arayın..."
            style={{
              backgroundColor: 'rgba(26, 26, 26, 1)',
              width: 750,
              maxWidth:750,
              height: 80,
              borderRadius: 20,
              paddingLeft: 20,
              borderWidth: 0,
              color: '#fff',
              // Placeholder rengini beyaz yapmak için CSS kullanımı
              '::placeholder': {
                color: 'white'
              }
            }}
          />
          <img
            src={require('../assets/Images/search.png')}
            style={{marginRight:20 }} // Resmin boyutu ve diğer stiller
          />
        </div>


        {/* <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Bir şey arayın..."
          style={{
            backgroundColor: 'rgba(26, 26, 26, 1)',
            width: 906,
            height: 80,
            borderRadius: 20,
            paddingLeft: 20,
            borderWidth: 0,
            color: '#fff',
            // Placeholder rengini beyaz yapmak için CSS kullanımı
            '::placeholder': {
              color: 'white'
            }
          }}
        /> */}
        <View style={{marginTop:10,padding:10,borderRadius:20}}>

            <div className='roboto' style={{fontSize:Sizes.altTitle, color:'#fff'}}>Paketler</div>



        

          
       
          <FlatList
            numColumns={3}
            data={datas}
            showsVerticalScrollIndicator={false}
            style={{width:906,height:720}}
            renderItem={({item}) => 
              <View style={{margin:15 ,borderRadius:20}}  onClick={() => handleMangaClick(item.id)}>
                <Image style={{width:257,height:257,backgroundColor:'#fff',borderTopLeftRadius:20,borderTopRightRadius:20}}/>
                <View style={{width:257,height:161,backgroundColor:Colors.lightBlack,borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
                  <View style={{flexDirection:'row', justifyContent:'space-between',margin:20,alignItems:'center'}}>
                    <div className='roboto' style={{fontSize:Sizes.altTitle, color:Colors.white}}>150 Coin</div>
                    <View style={{height:35,width:98,backgroundColor:'rgba(199, 241, 79, 0.4)', borderRadius:15, justifyContent:'center', alignItems:'center'}}>
                      <div className='roboto' style={{fontSize:Sizes.altTitle, color:Colors.green}}>₺25,00</div>
                    </View>
                  </View>
                  <View style={{alignItems:'center', justifyContent:'center'}}>
                    <Pressable  style={{flexDirection:'row', backgroundColor:'#C7F14F', width:162, height:42, borderRadius:10, alignItems:'center',justifyContent:'center',marginTop:10}}>
                      <div className='roboto' style={{color:'#111111', fontSize:16, fontWeight:'bold' }}>Sepete Ekle</div>
                      <Image style={{height:20,width:20,marginLeft:10}} source={require('../assets/Images/book.png')}/>
                    </Pressable>
                  </View>
                </View>
               
              </View>
            }
          />
        </View>


        
      </div>


      <View style={{marginRight:50}}>
        <View style={{ marginTop:30, marginLeft:30,flexDirection:'row'}}>
          <View style={{width:88, height:88, borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', alignItems:'center', justifyContent:'center'}}>
            <Image style={{height:28, width:28}} source={require('../assets/Images/noti.png')}/>
          </View>
          <View style={{width:257, height:88, marginLeft:10,borderRadius:20,backgroundColor:'rgba(26, 26, 26, 1)', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
            <Image style={{backgroundColor:'#D9D9D9',height:40,width:40, borderRadius:100}}/>
            <View>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:16}}>Yuki Tanaka</div>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:14}}>info@gmail.com</div>
            </View>
            <Pressable onPress={handlePresss}>
              <Image source={require('../assets/Images/down.png')} style={{height:7,width:14,}}/>
            </Pressable>
          </View>
        </View>
        {modal ? (<View  style={{backgroundColor:Colors.lightBlack,height:279, position:'absolute',width:250,right:4,top:105,padding:15, borderBottomLeftRadius:20,borderBottomRightRadius:20,zIndex:99}}>
            <View style={{borderWidth:0.5,borderColor:'#fff',marginTop:10,marginBottom:10}}></View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/aile.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Aile Modu</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/karanlik.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Karanlık Mod</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/tema.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Tema</Text>
            </View>
            <Image source={require('../assets/Images/renkler.png')} style={{height:24,width:212}}/>

            <Pressable onPress={() => navigate('/Profile')} style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/profilim.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Profilim</Text>
            </Pressable>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/ayarlar.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Ayarlar</Text>
            </View>
            <View style={{flexDirection:'row',marginTop:10}}>
              <Image source={require('../assets/Images/cikisYap.png')} style={{height:24,width:24}}/>
              <Text style={{color:Colors.white, fontSize:Sizes.regular,marginLeft:10}}>Çıkış Yap</Text>
            </View>
        </View>): null}
         
        

        <View style={{ width:350,height:700,marginLeft:30,backgroundColor:Colors.lightBlack,marginTop:10,borderRadius:20, justifyContent:'space-between'}}>
        
          <View style={{}}>
            
          <View style={{flexDirection:'row',justifyContent:'space-between',margin:20}}>
            <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Image source={require('../assets/Images/sepet.png')} style={{height:24,width:24,marginRight:10}}/>
              <div className='roboto' style={{color:'#FFFFFF', fontSize:24,}}>Sepet</div>
            </View>
            <View style={{flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
              <Image source={require('../assets/Images/cop.png')} style={{height:24,width:24,marginRight:10}}/>
              <div className='roboto' style={{color:'#D11616', fontSize:16,}}>Temizle</div>
            </View>
          </View>
          
          <View style={{borderWidth:1, borderColor:'#CCCCCC',margin:20}}></View>
          

         

          <View style={{margin:20, justifyContent:'space-between'}}>
            
              <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <View style={{alignItems:'center',justifyContent:'center'}}>
                  <Text style={{color:Colors.white, fontSize:20}}>150 Coin</Text>
                  <div className='name' style={{fontSize:16}}>indirim</div>
                </View>
                <View style={{alignItems:'center',justifyContent:'center'}}>
                  <div className='roboto' style={{color:Colors.green, fontSize:20,}}>₺ 25,00</div>
                  <div className='name' style={{fontSize:16}}>₺ -10,00</div>
                </View>
                
              </View>
              <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <View style={{alignItems:'center',justifyContent:'center'}}>
                  <Text style={{color:Colors.white, fontSize:20}}>150 Coin</Text>
                  <div className='name' style={{fontSize:16}}>indirim</div>
                </View>
                <View style={{alignItems:'center',justifyContent:'center'}}>
                  <div className='roboto' style={{color:Colors.green, fontSize:20,}}>₺ 25,00</div>
                  <div className='name' style={{fontSize:16}}>₺ -10,00</div>
                </View>
                
                </View>
              </View>
          </View>

          
          
          

            <View style={{margin:20}}>
              <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <div className='rotobo' style={{fontSize:24,color:Colors.green}}>Total</div>
                <div className='rotobo' style={{fontSize:24,color:Colors.green}}>₺30,00</div>

              </View>
              <View style={{borderWidth:1, borderColor:'#CCCCCC',marginTop:20}}></View>
              <View style={{backgroundColor:Colors.green, height:69, width:190,alignItems:'center', alignSelf:'center',justifyContent:'center',margin:20,borderRadius:15}}>
                <div className='roboto' style={{fontSize:18,color:Colors.darkBlack}}>Satın Al</div>
              </View>

            </View>
          </View>

        
      </View>

      

      
    </div>
    )
  }


  
  
  return (
    <body>
     {name > 906 && web()}
     {name < 906 && <Coin/>}
    </body>
   
  );
}

const styles = StyleSheet.create({
  container:{
  
    flexDirection:'row',
    backgroundColor:'rgba(17, 17, 17, 1)',
    height:Dimensions.get('window').height,
    width:Dimensions.get('window').width,
    ...Platform.select({
      web: {
        height: '100vh',
        width: '100vw'
      }
    })
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 10,
    marginTop: 20,
    marginBottom: 20,
    scrollbarWidth: 'none', // Firefox için
    WebkitOverflowScrolling: 'touch', // Safari için
    '-ms-overflow-style': 'none' // IE11 için
  },

  

  navBar:{
    backgroundColor:Colors.lightBlack,
    width:100,
    height:Dimensions.get('window').height/1.2,
    marginTop:20,
    borderRadius:20,
    marginLeft:150,

  },
  navIcons:{
    height:40,
    width:40,
    marginTop:25,
  },
  textInputs:{
    backgroundColor: 'rgba(26, 26, 26, 1)',
    width: 906,
    height: 80,
    borderRadius: 20,
    paddingLeft: 20,
    borderWidth:0,
    color:'#fff'
  },
  bottoms:{
    backgroundColor:'rgba(26, 26, 26, 1)',
    marginTop:15,
    height:490,
    width:350,
    marginLeft:30,
    borderRadius:20
  },
  desc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    padding:5
  },
  rightDesc:{
    borderWidth: 1,
    borderColor:'rgba(255, 255, 255, 0.3)',
    backgroundColor:'#2A2A2A',
    height: 31,
    width: 'auto',
    minWidth: 97,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    overflow: 'hidden',
    marginLeft:10 ,
    padding:5
  },
})

export default Purchase;
