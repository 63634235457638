import React,{useState} from 'react'
import { SafeAreaView, View, Text, Image, TouchableOpacity , ScrollView, FlatList, StatusBar} from 'react-native'
import { useNavigate } from 'react-router-dom';


const Coin = (props) => {

  const fakeDate = [1,1,1,1,1,1,1,1]
  const navigate = useNavigate();


  const [openModel, setOpenModal] = useState(false)
  const [altopenModel, setAltOpenModal] = useState(false)
  
  const [popUp, setPopUp] = useState(false)


  const goHomePage = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goExpolore = () => {
    console.log('Naber'); 
    navigate(`/Expolore`,{replace:true}); 
  };

  const goBookCase = () => {
    console.log('Naber'); 
    navigate(`/BookCase`,{replace:true}); 
  };


  const handlePress = () => {
    console.log('Naber'); // Konsola mesaj yazdırma
    navigate(`/Detail/${'anill'}`,{replace:true}); // 'Selam' sayfasına yönlendirme
  };

  const goLikedPAge = () => {
    console.log('Naber'); 
    navigate(`/LikedPage`,{replace:true}); 
  };

  const goPurchase = () => {
    console.log('Naber',{replace:true}); 
    navigate(`/Purchase`); 
  };

  const goProfile = () => {
    navigate(`/Profile`,{replace:true}); 
  };

  const goNotification = () => {
    navigate(`/Notification`,{replace:true});
    setOpenModal(false) 
  };



  function draweModalRender(){
    return(
      <SafeAreaView style={{flex:1,position:'absolute',backgroundColor:'#1A1A1A',width:'100%',height:'100%'}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
          <TouchableOpacity style={{flexDirection:'row'}} onPress={() => setOpenModal(false)}>
            <Image style={{height:40,width:40}} source={require('../assets/cik.png')}/>
            <Image style={{marginLeft:10,height:40,width:40}} source={require('../assets/popuplogo.png')}/>

          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={goNotification}>
              <Image style={{height:40,width:40}}  source={require('../assets/bildirim.png')}/>
            </TouchableOpacity>
            <Image  style={{marginLeft:10,height:40,width:80}} source={require('../assets/giris.png')}/>
          </View>
        </View>
        <View style={{backgroundColor:'#1A1A1A', flex:1, alignItems:'center',marginTop:20}}>
          <View>
            <TouchableOpacity  onPress={goHomePage} style={{flexDirection:'row', alignItems:'center'}}>
              <Image style={{height:40,width:40}} source={require('../assets/anasayfa.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Anasayfa</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goExpolore} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kesfet.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Keşfet</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goBookCase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/kitaplik.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Kitaplık</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goLikedPAge} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/favoriler.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Favoriler</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={goPurchase} style={{flexDirection:'row', alignItems:'center',marginTop:5}}>
              <Image style={{height:40,width:40}} source={require('../assets/coin.png')}/>
              <Text style={{color:'#fff',fontSize:22,fontWeight:'500'}}>Coin Satın Al</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    )
  }

  function altModal(){
    return(
      <View style={{backgroundColor:'#2A2A2A', height:'48%',}}>
        <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:15}}>
          <View style={{flexDirection:'row',alignItems:'center',marginLeft:20}}>
            <Image style={{marginRight:10, height:20,width:20}} source={require('../assets/beyazsepet.png')}/>
            <Text style={{color:'#fff', fontSize:22,fontWeight:'500'}}>Sepet</Text>
          </View>
          <TouchableOpacity onPress={() => setAltOpenModal(false)} style={{flexDirection:'row',alignItems:'center',marginRight:20}}>
            <Image style={{marginRight:10,height:20,width:20}} source={require('../assets/temizle.png')}/>
            <Text style={{color:'#fff', fontSize:18,fontWeight:'500',color:'red'}}>Temizle</Text>
          </TouchableOpacity>
        </View>
        <View style={{borderBottomWidth:1,borderBottomColor:'gray',marginLeft:20,marginRight:20,marginTop:10}}></View>

        <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:15}}>
          <View style={{alignItems:'center',marginLeft:20}}>
            <Text style={{color:'#fff', fontSize:20,fontWeight:'500'}}>150 coin</Text>
            <Text style={{color:'gray', fontSize:16,fontWeight:'400'}}>İndirim</Text>
          </View>
          <TouchableOpacity style={{alignItems:'center',marginRight:20}}>
            <Text style={{color:'#fff', fontSize:20,fontWeight:'500',color:'#C7F14F'}}>₺ 25,00</Text>
            <Text style={{color:'gray', fontSize:16,fontWeight:'400'}}>₺ -10,00</Text>
          </TouchableOpacity>
        </View>
        <View style={{flexDirection:'row', justifyContent:'space-between',marginTop:15}}>
          <View style={{alignItems:'center',marginLeft:20}}>
            <Text style={{color:'#fff', fontSize:20,fontWeight:'500'}}>150 coin</Text>
            <Text style={{color:'gray', fontSize:16,fontWeight:'400'}}>İndirim</Text>
          </View>
          <TouchableOpacity style={{alignItems:'center',marginRight:20}}>
            <Text style={{color:'#fff', fontSize:20,fontWeight:'500',color:'#C7F14F'}}>₺ 25,00</Text>
            <Text style={{color:'gray', fontSize:16,fontWeight:'400'}}>₺ -10,00</Text>
          </TouchableOpacity>
        </View>

        <View style={{borderBottomWidth:1,borderBottomColor:'gray',marginLeft:20,marginRight:20,marginTop:10}}></View>

        <View style={{margin:20, flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
          <View style={{flexDirection:'row'}}>
            <Image style={{marginRight:10, height:20,width:20}} source={require('../assets/arrowsdown.png')}/>
            <View style={{flexDirection:'row'}}>
              <Text style={{color:'#fff', fontSize:18, fontWeight:'500'}}>Total:</Text>
              <Text style={{color:'#C7F14F', fontSize:18, fontWeight:'500'}}> ₺ 30,00</Text>
            </View>

          </View>

          <TouchableOpacity onPress={() => setPopUp(true)} style={{backgroundColor:'#C7F14F',height:51,width:105,alignItems:'center', justifyContent:'center', borderRadius:15}}>
            <Text style={{color:'black', fontSize:16,fontWeight:'600'}}>Satın Al</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }


  function popUpRender(){
    return(
      <View style={{flex:1, position:'absolute',backgroundColor: 'rgba(0, 0, 0, 0.5)', height:'100%',width:'100%', alignItems:'center',justifyContent:'center',}}>
        <View style={{backgroundColor:'#2A2A2A', height:143,width:288, marginTop:200,alignItems:'center', justifyContent:'center',borderRadius:20}}>
          <View style={{flexDirection:'row', justifyContent:'space-between',width:'90%',}}>
            <TouchableOpacity onPress={() => setPopUp(false)} style={{backgroundColor:'#6A6A6A',height:40,width:120, flexDirection:'row', alignItems:'center', justifyContent:'center',borderRadius:20}}>
              <Image style={{height:20,width:20}} source={require('../assets/popexit.png')}/>
              <Text style={{color:'#fff',fontSize:14, fontWeight:'500',marginLeft:5}}>İptal</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setPopUp(false)} style={{backgroundColor:'#C7F14F',height:40,width:120, flexDirection:'row', alignItems:'center', justifyContent:'center',borderRadius:20}}>
            <Image style={{height:20,width:20}} source={require('../assets/popconfirm.png')}/>
              <Text style={{color:'black',fontSize:14, fontWeight:'500',marginLeft:5}}>Onayla</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  return (
    <SafeAreaView style={{flex:1,backgroundColor:'black'}}>
      <StatusBar backgroundColor={'black'} barStyle={'light-content'}/>

      <ScrollView>
      
      <View style={{flexDirection:'row', justifyContent:'space-between',margin:10}}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image style={{height:40,width:90}} source={require('../assets/menu.png')}/>
        </TouchableOpacity>
        <View style={{flexDirection:'row'}}>
          <TouchableOpacity onPress={goNotification}>
            <Image style={{height:40,width:40}} source={require('../assets/bildirim.png')}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={goProfile}>
            <Image style={{marginLeft:10,height:40,width:128}} source={require('../assets/kullanici.png')}/>
          </TouchableOpacity>
        </View>
      </View>
     
      

      
      <View style={{marginLeft:10,marginTop:10}}>
        <Text style={{color:'#ffff', fontSize:22,fontWeight:'600'}}>Paketler</Text>
      </View>

      <FlatList
        data={fakeDate}
        numColumns={2}
        renderItem={() =>
          <View style={{margin:10,width:'45%',backgroundColor:'#1A1A1A'}}>
            <Image style={{backgroundColor:'#fff',height:150,width:'100%', borderTopRightRadius:10,borderTopLeftRadius:10}}/>
            <Text style={{marginLeft:25,fontSize:16,fontWeight:'600', color:'#fff',marginTop:10}}>150 Coin</Text>
            <View style={{marginLeft:25,marginTop:10,backgroundColor:'#97B239',width:79,height:28,borderRadius:20, alignItems:'center', justifyContent:'center'}}>
              <Text style={{fontSize:14,fontWeight:'400', color:'#C7F14F'}}>₺ 25</Text>
            </View>
            <View style={{marginLeft:20,marginTop:10}}>
              <TouchableOpacity onPress={() => setAltOpenModal(true)} style={{backgroundColor:'#C7F14F',height:45,width:134, flexDirection:'row',alignItems:'center', justifyContent:'center',borderRadius:15}}>
                <Text style={{color:'black', fontWeight:'600'}}>Satın Al</Text>
                <Image style={{marginLeft:10, height:20,width:20}} source={require('../assets/sepet.png')}/>
              </TouchableOpacity>

            </View>
          </View>
        }
      />
      {altopenModel == true && altModal()}

      </ScrollView>
      

      {openModel == true && draweModalRender()}
      {popUp == true && popUpRender()}
      
    </SafeAreaView>
  )
}

export default Coin